import React from "react";
import SearchList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.id}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "Keyword Search",
      render: (record) => <span>{record.keyword || "---"}</span>,
      key: "keywordSearch",
      width: "200px",
    },
    {
      title: "Count",
      render: (record) => <span>{record.count || "---"}</span>,
      key: "count",
      width: "100px",
    },
    {
      title: "View",
      render: (record) => (
        <span
          onClick={() =>
            navigate("/keywordSearch/userDetail", {
              state: { record },
            })
          }
          className="text-primary-brown cursor-pointer"
        >
          User Detail
        </span>
      ),
      key: "userDetail",
    },
  ];

  return (
    <Widget className="p-5">
      <TableHeader />
      <SearchList
        columns={columns}
        apiName="getSearchWord"
        recordName="searchList"
      />
    </Widget>
  );
};

export default Index;
