import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const DetailModal = ({
  heading,
  children,
  modalName,
  width,
  customClassName,
}) => {
  const dispatch = useDispatch();
  const crudProps = useSelector((state) => state.CrudR);
  const closeModal = () => {
    dispatch({ type: "Hide_Modal", modalName: modalName });
  };
  return (
    <Modal
      footer={null}
      onCancel={() => closeModal()}
      open={crudProps[modalName]}
      title={heading}
      width={width}
      className={customClassName}
      centered
    >
      <div
        style={
          heading ? { borderTop: "1px solid #EAEAEA" } : { marginTop: "20px" }
        }
      >
        {children}
      </div>
    </Modal>
  );
};
export default DetailModal;
