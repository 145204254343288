import React, { useRef } from "react";
import TableTitle from "../../components/Table/components/TableTitle";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import { IoArrowBackOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import UserList from "../../components/Table";
import Widget from "../../components/Widget";
const ViewUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log("==state====", state, location);
  const targetRef = useRef();
  const { PostCountUserDetails } = useSelector((state) => state.CrudR);
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
    },
    {
      title: "User Name",
      render: (record) => <span>{record.username}</span>,
      key: "userName",
    },
    {
      title: "Full Name",
      render: (record) => <span>{record.fullName}</span>,
      key: "fullName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email}</span>,
      key: "email",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber}</span>,
      key: "phone",
    },
    {
      title: "View Count",
      render: (record) => <span>{record.count}</span>,
      key: "phone",
    },
  ];
  return (
    <Widget className="p-5">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-3">
          <IoArrowBackOutline className="cursor-pointer" size={30} onClick={() => navigate(-1)} />
          <TableTitle title={`${state && state.title} - ${state && state.postType}`} />
        </div>
        <div className="flex items-center gap-2 flex-wrap justify-end">
          <Button
            onClick={(e) => exportToCSV(PostCountUserDetails, "Users")}
            className="drop-shadow bg-primary-brown text-[#fff] hover:text-[#fff]"
            size="large"
          >
            Excel download
          </Button>
          <Button
            onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
            className="drop-shadow bg-primary-brown text-[#fff] hover:text-[#fff]"
            size="large"
          >
            PDF download
          </Button>
        </div>
      </div>

      <div ref={targetRef}>
        <UserList
          columns={columns}
          apiName="getPostCountUserDetails"
          customQuery={{
            _id: state && state._id,
          }}
          recordName="PostCountUserDetails"
        />
      </div>
    </Widget>
  );
};

export default ViewUser;
