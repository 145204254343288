import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import ServicesCategoryForm from "./ServicesCategoryForm";

const EditServicesCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_ServicesCategory_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_ServicesCategory_MODAL) {
      form.resetFields();
    }
  }, [EDIT_ServicesCategory_MODAL]);
  return (
    <>
      {EDIT_ServicesCategory_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="editCategories"
          recordName="servicesCategoryList"
          requestType="EditRecord"
          heading="Edit Category"
          modalName={"EDIT_ServicesCategory_MODAL"}
          initialData={initialValue}
          width={"500px"}
          extraValueObj={{ type: "service" }}
        >
          <ServicesCategoryForm form={form} />
        </EditFormModal>
      )}
    </>
  );
};

export default EditServicesCategory;
