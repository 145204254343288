// import React, { useState } from "react";
// import { Form } from "antd";
// import AddFormModal from "../../../../components/Modal/AddFormModal";
// import CategoryForm from "./CategoryForm";

// const AddCategory = () => {
//   const [ImageUrl, setImageUrl] = useState();
//   const [form] = Form.useForm();

//   return (
//     <AddFormModal
//       form={form}
//       modalName="Add_Category_Modal"
//       heading="Add Category"
//       apiName="addCategory"
//       recordName="categoryList"
//       width={"500px"}
//       addButtonName="Add Category"
//       extraValueObj={{ imageUrl: ImageUrl }}
//     >
//       <CategoryForm setImageUrl={setImageUrl} />
//     </AddFormModal>
//   );
// };

// export default AddCategory;


import React, { useState } from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import CategoryForm from "./CategoryForm";

const AddCategory = () => {
  const [ImageUrl, setImageUrl] = useState();
  const [form] = Form.useForm();
  const [reset, setReset] = useState(false);

  const resetForm = () => {
    form.resetFields();
    setImageUrl(undefined); // Reset image URL
    setReset(true); // Trigger reset in CategoryImageUploader
    setTimeout(() => setReset(false), 0); // Reset the flag after a brief timeout
  };

  return (
    <AddFormModal
      form={form}
      modalName="Add_Category_Modal"
      heading="Add Category"
      apiName="addCategory"
      recordName="categoryList"
      width={"500px"}
      addButtonName="Add Category"
      extraValueObj={{ imageUrl: ImageUrl }}
      resetForm={resetForm} // Pass resetForm prop
    >
      <CategoryForm setImageUrl={setImageUrl} reset={reset} />
    </AddFormModal>
  );
};

export default AddCategory;
