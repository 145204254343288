import { DataGetAction } from "../../../redux/actions/CommonHttp";
import { Avatar, Modal, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const { initialValue, ProductDetail, fetchViewOrderAgain } = useSelector((state) => state.CrudR);


  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      dispatch(DataGetAction("makeOrderView", { query: "all", orderId: initialValue?.[0]['orderId'] }, "FetchRecord", "newOrders", "fetchingOrder"));
    }

  }, [initialValue, fetchViewOrderAgain]);

  const renderShipping = (data) => {
    if (data && data.shipmentMethod && data.shipmentMethod.shipMentType) {
      return <span>{data.shipmentMethod.shipMentType}</span>;
    } else {
      return <span>Free</span>;
    }
  };

  const renderGrandTotal = (data) => {
    if (data && data.length > 0) {
      let price = 0;
      data.map((Item) => {
        price += parseFloat(Item.totalPrice);
      });
      return (
        <span className="flex flex-row justify-around items-center border-1 p-2 gap-4 max-w-[200px]">
          <span className="border-r-1 px-2 font-medium">Grand Total</span>{" "}
          <span className="font-regular">
            {" "}
            {process.env.NEXT_PUBLIC_CURRENCY} {price}
          </span>
        </span>
      );
    }
  };

  const modalColumn = [
    {
      title: "Name",
      key: "name",
      render: (record) => (
        <div className="flex flex-row items-center gap-2">
          <Avatar src={record?.productImages[0]} />
          <span>{record?.productName}</span>
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text) => (
        <span>
          {process.env.NEXT_PUBLIC_CURRENCY}
          {text}
        </span>
      ),
    },
    {
      title: "Packaging",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Shipping",
      key: "shipping",
      render: (record) => renderShipping(record),
    },

    {
      title: "Total Price",
      key: "totalPrice",
      render: (record) => (
        <span>
          {process.env.NEXT_PUBLIC_CURRENCY}
          {parseFloat(record.totalPrice)}
        </span>
      ),
    },
  ];

  const handleCancel = () => {
    dispatch({
      type: "Hide_Modal",
      modalName: "ProductDetail",
    });
  };

  const renderData = () => {
    if (initialValue && ProductDetail) {
      return (
        <>
          <div>{initialValue && <Table pagination={false} dataSource={initialValue} columns={modalColumn} />}</div>
          <div className="flex flex-row justify-end mt-4">{initialValue && renderGrandTotal(initialValue)}</div>
        </>
      );
    }
  };

  return (
    <Modal width={800} title={<span className="font-regular">Product Detail</span>} footer={null} open={ProductDetail} onCancel={handleCancel}>
      {renderData()}
    </Modal>
  );
};

export default ProductDetail;
