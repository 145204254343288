import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import AgeForm from "./AgeForm";

const EditAge = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_AGE_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_AGE_MODAL) {
      form.resetFields();
    }
  }, [EDIT_AGE_MODAL]);
  return (
    <>
      {EDIT_AGE_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="updateAge"
          recordName="ageList"
          requestType="EditRecord"
          heading="Edit Age"
          modalName={"EDIT_AGE_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <AgeForm />
        </EditFormModal>
      )}
    </>
  );
};

export default EditAge;
