import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavCard = ({ title, count, icon, iconColorbg, color, routeName, customClick, width=200 }) => {
  const location = useLocation()
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (customClick) {
          customClick()
        } else {
          navigate(routeName)
        }
      }}
      style={{ borderColor: color, background: location && location.pathname === routeName ? iconColorbg : "" }}
      className={` h-[70px] flex items-center border p-4 justify-between rounded-[10px] cursor-pointer`}
    >
      <div>
        <h6 className="mb-[-2px] w-[140px] font-roboto-regular">{title}</h6>
        <span className="font-bold">{count}</span>
      </div>
      <div
        className="p-1 rounded"
        style={{ backgroundColor: iconColorbg, color: color }}
      >
        {icon}
      </div>
    </div>
  );
};

export default NavCard;
