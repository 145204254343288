import { Tabs } from "antd";
import React from "react";
import ServiceCategory from "./ServicesCategory";
import ServicesSubCategory from "./ServicesSubCategory";
import CategoryLinking from "./CategoryLinking";
const index = () => {
  const items = [
    {
      key: "1",
      label: "Services Category",
      children: <ServiceCategory />,
    },
    {
      key: "2",
      label: "Services Sub Category",
      children: <ServicesSubCategory />,
    },
    {
      key: "3",
      label: "Category Linking",
      children: <CategoryLinking />,
    },
  ];
  return (
    <Tabs defaultActiveKey="1" type="card" className="h-full" items={items} />
  );
};

export default index;
