import React, { useEffect, useState } from "react";
import DetailModal from "../../../components/Modal/DedailModal";
import { Button, Col, Form, Input, Row } from "antd";
import ImageUploader from "../../../components/Uploader";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";

const EditFormModal = () => {
  const dispatch = useDispatch();
  const { initialValue } = useSelector((state) => state.CrudR);
  const { updateSpin } = useSelector((state) => state.AuthR);

  const [imageUrl, setImageUrl] = useState(
    initialValue && initialValue.profileImageUrl
      ? initialValue.profileImageUrl
      : ""
  );

  useEffect(() => {
    if (initialValue) {
      setImageUrl(initialValue.profileImageUrl);
    }
  }, [initialValue]);

  const onFinish = (values) => {
    values["profileImageUrl"] = imageUrl;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        values,
        "UPDATE_PROFILE",
        "",
        "UPDATE_PROFILE_SPIN",
        "Edit_Profile"
      )
    );
  };
  return (
    <DetailModal
      modalName="Edit_Profile"
      heading={"Edit Profile"}
      width={"600px"}
    >
      <Form initialValues={initialValue} onFinish={onFinish} layout="vertical">
        <Row gutter={[16, 16]} className="mt-4">
          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true }]}
            >
              <PhoneInput country={"us"} inputStyle={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <ImageUploader setImageUrl={setImageUrl} imageUrl={imageUrl} />
          </Col>
        </Row>
        <div className="w-full flex justify-end items-center gap-2">
          <Button
            onClick={() => {
              dispatch({
                type: "Hide_Modal",
                modalName: "Edit_Profile",
              });
            }}
          >
            Cancel
          </Button>
          <Button
            loading={updateSpin}
            disabled={updateSpin}
            htmlType="submit"
            className="bg-primary-brown"
            type="primary"
          >
            Update
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default EditFormModal;
