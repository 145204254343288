import React, { useState } from "react";
import DetailModal from "../../../components/Modal/DedailModal";
import { Button, Col, Form, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";

const AddLinkModal = () => {
  const dispatch = useDispatch();
  const { initialValue } = useSelector((state) => state.CrudR);
  const { updateSpin } = useSelector((state) => state.AuthR);
  console.log("====initialValue===",initialValue)
  const onFinish = (values) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        values,
        "UPDATE_PROFILE",
        "",
        "UPDATE_PROFILE_SPIN",
        "Add_Links"
      )
    );
  };
  return (
    <DetailModal modalName="Add_Links" heading={"Add Links"} width={"600px"}>
      <Row gutter={[16, 16]} className="mt-4">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form
            initialValues={initialValue}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="facebookLink"
              label="Facebook"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="instagramLink"
              label="Instagram"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="linkedInLink"
              label="Linked In"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <div className="w-full flex justify-end items-center gap-2">
              <Button
                onClick={() => {
                  dispatch({
                    type: "Hide_Modal",
                    modalName: "Add_Links",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className="bg-primary-brown"
                type="primary"
                loading={updateSpin}
                disabled={updateSpin}
              >
                Update
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </DetailModal>
  );
};

export default AddLinkModal;
