import React, { useEffect, useState } from "react";
import BannerForm from "./BannerForm";
import { Form } from "antd";
import AddFormModal from "../../../components/Modal/AddFormModal";
import { useSelector } from "react-redux";

const AddBanner = () => {
  const [form] = Form.useForm();
  const { Add_Banner_Modal } = useSelector((state) => state.CrudR);
  const [profileImage, setProfileImage] = useState();
  useEffect(() => {
    if (!Add_Banner_Modal) {
      setProfileImage("");
    }
  }, [Add_Banner_Modal]);
  return (
    <AddFormModal
      form={form}
      modalName="Add_Banner_Modal"
      heading="Add Banner"
      apiName="addBanner"
      recordName="bannerList"
      width={"600px"}
      addButtonName="Add Banner"
      extraValueObj={{ profileImageUrl: profileImage }}
    >
      <BannerForm
        formType="add"
        setProfileImage={setProfileImage}
        profileImage={profileImage}
      />
    </AddFormModal>
  );
};

export default AddBanner;
