import React, { useRef } from "react";
import SearchList from "../../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../../components/Widget";
import { useLocation } from "react-router";

const Index = () => {
  const targetRef = useRef();
  const location = useLocation();
  console.log("====params", location);
  const { state } = location;
  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.id}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "User Name",
      render: (record) => <span>{record.username || "---"}</span>,
      key: "userName",
      width: "150px",
    },
    {
      title: "Full Name",
      render: (record) => <span>{record.fullName || "---"}</span>,
      key: "fullName",
      width: "200px",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
      width: "200px",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phone",
      width: "200px",
    },
    {
      title: "Search Count",
      render: (record) => <span>{record.count}</span>,
      key: "searchCount",
    },
  ];

  return (
    <Widget className="p-5">
      <TableHeader title={state && state.record && state.record.keyword} targetRef={targetRef} />
      <div ref={targetRef}>
        <SearchList
          columns={columns}
          apiName="getDetailByKeyword"
          customQuery={{
            keyword: state && state.record && state.record.keyword,
          }}
          recordName="findKeywordsDetail"
        />
      </div>
    </Widget>
  );
};

export default Index;
