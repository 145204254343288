// import { Button, Form, Modal } from "antd";
// import React, { useEffect } from "react";
// import { DataRequestAction } from "../../redux/actions/CommonHttp";
// import { useDispatch, useSelector } from "react-redux";

// const AddFormModal = ({
//   heading,
//   children,
//   form,
//   method = "POST",
//   apiName,
//   requestType = "AddRecord",
//   loarderName = "AddFormModalSpin",
//   recordName,
//   saveButtonName = "Submit",
//   cancelButtonName = "Cancel",
//   width,
//   extraValueObj,
//   addButtonName = "Add",
//   modalName,
// }) => {
//   const CrudRProps = useSelector((state) => state.CrudR);
//   const { addRecordSuccess, addRecordSpin } = CrudRProps;
//   const dispatch = useDispatch();
//   const onFinish = (values) => {
//     dispatch(
//       DataRequestAction(
//         method,
//         apiName,
//         { ...values, ...extraValueObj },
//         requestType,
//         recordName,
//         loarderName
//       )
//     );
//   };

//   useEffect(() => {
//     if (CrudRProps && CrudRProps[modalName]) {
//       form.resetFields();
//     }
//   }, [CrudRProps && CrudRProps[modalName]]);

//   useEffect(() => {
//     if (addRecordSuccess) {
//       dispatch({ type: "Hide_Modal", modalName: modalName });
//       form.resetFields();
//       dispatch({ type: "RESET_CRUD_STATE" });
//     }
//   }, [addRecordSuccess]);
//   return (
//     <>
//       <Button
//         type="primary"
//         className="bg-primary-brown"
//         onClick={() => {
//           dispatch({ type: "Show_Modal", modalName: modalName });
//         }}
//       >
//         {addButtonName}
//       </Button>
//       <Modal
//         width={width}
//         footer={null}
//         onCancel={() => {
//           dispatch({ type: "Hide_Modal", modalName: modalName });
//           form.resetFields();
//         }}
//         open={CrudRProps && CrudRProps[modalName]}
//         title={heading}
//         centered
//       >
//         <Form form={form} layout="vertical" onFinish={onFinish}>
//           {children}
//           <div
//             className="pt-2 gap-2 flex justify-end"
//             style={{ borderTop: "1px solid #DEDEDE" }}
//           >
//             <Button
//               className="hover:bg-[#c94f00]"
//               onClick={() => {
//                 dispatch({ type: "Hide_Modal", modalName: modalName });
//                 form.resetFields();
//               }}
//             >
//               {cancelButtonName}
//             </Button>
//             <Button
//               loading={addRecordSpin}
//               disabled={addRecordSpin}
//               htmlType="submit"
//               type="primary"
//               className="bg-primary-brown"
//               style={{ boxShadow: "none" }}
//             >
//               {saveButtonName}
//             </Button>
//           </div>
//         </Form>
//       </Modal>
//     </>
//   );
// };
// export default AddFormModal;




import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";

const AddFormModal = ({
  heading,
  children,
  form,
  method = "POST",
  apiName,
  requestType = "AddRecord",
  loarderName = "AddFormModalSpin",
  recordName,
  saveButtonName = "Submit",
  cancelButtonName = "Cancel",
  width,
  extraValueObj,
  addButtonName = "Add",
  modalName,
  resetForm, // Add resetForm prop
}) => {
  const CrudRProps = useSelector((state) => state.CrudR);
  const { addRecordSuccess, addRecordSpin } = CrudRProps;
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(
      DataRequestAction(
        method,
        apiName,
        { ...values, ...extraValueObj },
        requestType,
        recordName,
        loarderName
      )
    );
  };

  useEffect(() => {
    if (CrudRProps && CrudRProps[modalName]) {
      form.resetFields();
      resetForm && resetForm(); // Reset form and image URL
    }
  }, [CrudRProps && CrudRProps[modalName]]);

  useEffect(() => {
    if (addRecordSuccess) {
      dispatch({ type: "Hide_Modal", modalName: modalName });
      form.resetFields();
      resetForm && resetForm(); // Reset form and image URL
      dispatch({ type: "RESET_CRUD_STATE" });
    }
  }, [addRecordSuccess]);

  return (
    <>
      <Button
        type="primary"
        className="bg-primary-brown"
        onClick={() => {
          dispatch({ type: "Show_Modal", modalName: modalName });
        }}
      >
        {addButtonName}
      </Button>
      <Modal
        width={width}
        footer={null}
        onCancel={() => {
          dispatch({ type: "Hide_Modal", modalName: modalName });
          form.resetFields();
          resetForm && resetForm(); // Reset form and image URL
        }}
        open={CrudRProps && CrudRProps[modalName]}
        title={<div style={{ borderBottom: '1px solid #DEDEDE', paddingBottom: '8px' }}>{heading}</div>}
        centered
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          
          {children}
          <div
            className="pt-2 gap-2 flex justify-end"
            style={{ borderTop: "1px solid #DEDEDE" }}
          >
            <Button
              className="hover:bg-[#c94f00]"
              onClick={() => {
                dispatch({ type: "Hide_Modal", modalName: modalName });
                form.resetFields();
                resetForm && resetForm(); // Reset form and image URL
              }}
            >
              {cancelButtonName}
            </Button>
            <Button
              loading={addRecordSpin}
              disabled={addRecordSpin}
              htmlType="submit"
              type="primary"
              className="bg-primary-brown"
              style={{ boxShadow: "none" }}
            >
              {saveButtonName}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddFormModal;
