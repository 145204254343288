import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DetailCard = ({ title, count, icon, iconColorbg, color, routeName, image, customClick, background="#ffffff" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div

      onClick={() => {
        if (customClick) {
          customClick()
        } else {
          navigate(routeName)
        }

      }}
      style={{ borderColor: background, cursor:'pointer', background: location && location.pathname === routeName ? iconColorbg : "", backgroundColor: background }}
      className="w-[300px] flex items-center border p-4 justify-start rounded-xl shadow-lg"
    >
      <div className="p-1 rounded mr-5" style={{ backgroundColor: iconColorbg, color: color }}>
        {icon}
      </div>
      <div className="flex-grow mr-6">
        <h5 className="mb-[5px] font-roboto-regular text-[13px] text-[#707070] overflow-hidden whitespace-nowrap">{title}</h5>
        <span className="font-bold text-2xl text-[#535353]">{count}</span>
      </div>
      <div className="p-1 rounded" style={{ color: color }}>
        <img src={image} alt={title} style={{ maxWidth: "70px", height: "auto" }} />
      </div>
    </div>
  );
};

export default DetailCard;
