import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import SubCategoryForm from "./SubCategoryForm";

const AddCategoryLinking = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName="Add_SubCategory_Modal"
      heading="Add Category Link"
      apiName="addCategoriesLinking"
      recordName="CategoryLinkList"
      width={"500px"}
      addButtonName="Add Category Link"
      extraValueObj={{ type: "service" }}
    >
      <SubCategoryForm form={form} />
    </AddFormModal>
  );
};

export default AddCategoryLinking;
