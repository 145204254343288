import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import CurdReducer from "./CRUDReducer";
import AuthReducer from "./Auth";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    CrudR: CurdReducer,
    AuthR: AuthReducer,
  });

export default rootReducer;
