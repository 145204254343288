import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BsCalendar3 } from "react-icons/bs";
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 100,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 500,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 1000,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 500,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 800,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 1000,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 1300,
  },
];
const DataCard = ({ stroke }) => {
  return (
    <ResponsiveContainer width="100%" height={86}>
      <AreaChart
        width={200}
        height={60}
        data={data}
        margin={{
          top: 50,
          right: 0,
          left: 0,
        }}
      >
        <Area type="monotone" dataKey="amt" stroke={stroke} fill="#a5a5a580" />
      </AreaChart>
    </ResponsiveContainer>
  );
};
const GraphCards = ({ totalSale }) => {
  return (
    <div className="h-[86px] w-full grid grid-cols-2 gap-3">
      <div className="relative rounded-lg overflow-hidden bg-[#DC4F62] ">
        <DataCard stroke={"#DC4F62"} />
        <div className="absolute h-full w-full top-0 px-5 py-3 flex items-center justify-between">
          <div>
            <p className="mb-1 mt-2 text-[14px] text-[#fff] font-roboto-regular">
              TOTAL PRODUCT SALES
            </p>
            <p className="text-[30px] text-[#fff]">
              ${totalSale && totalSale.totalProductSale}
            </p>
          </div>
          <span className="p-1 bg-[#ffffff30] rounded-lg">
            <RiMoneyDollarCircleLine size={30} fill="#fff" />
          </span>
        </div>
      </div>
      <div className="relative rounded-lg overflow-hidden bg-[#36827F]">
        <DataCard stroke={"#36827F"} />
        <div className="absolute h-full w-full top-0 px-5 py-3 flex items-center justify-between">
          <div>
            <p className="mb-2 mt-2 text-[14px] text-[#fff] font-roboto-regular">
              TOTAL SERVICE BOOKINGS
            </p>
            <p className="text-[30px] text-[#fff]">
              ${totalSale && totalSale.totalServicePrice}
            </p>
          </div>
          <span className="p-2 bg-[#ffffff30] rounded-lg">
            <BsCalendar3 size={22} fill="#fff" />
          </span>
        </div>
      </div>
    </div>
  );
};
export default GraphCards;
