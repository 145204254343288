import React, { useEffect, useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import BannerUploader from "../../../components/Uploader/bannerUploader";
const FAQModal = ({ heading }) => {
  const [form] = Form.useForm();
  const { initialValue, Open_FAQ_Page_Modal } = useSelector(
    (state) => state.CrudR
  );
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (initialValue && initialValue.imageUrl) {
      setImageUrl(initialValue.bannerImageUrl);
    } else {
      setImageUrl("");
    }
    if (!Open_FAQ_Page_Modal) {
      form.resetFields();
    }
  }, [Open_FAQ_Page_Modal]);
  return (
    <EditFormModal
      apiName={"editPageData"}
      initialData={initialValue}
      form={form}
      requestType="FetchRecord"
      recordName={"FaqsList"}
      modalName={"Open_FAQ_Page_Modal"}
      heading={"Add FAQ Question"}
      loaderCase="TableSpinner"
      spinnerName="fetchingSpinner"
      extraValueObj={{
        pageType: "faq",
        bannerImageUrl: imageUrl,
      }}
    >
      <Form.Item
        label="Hero Heading"
        name={"heroHeading"}
        rules={[{ required: true, message: "Hero heading required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"title"}
        label="Heading"
        rules={[{ required: true, message: "Heading required" }]}
      >
        <Input />
      </Form.Item>

      <h4 className="text-[16px] font-semibold mb-3">Add FAQ Questions</h4>
      <Form.List name="questionAndAnswer">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} className="flex flex-col w-full">
                <div className="flex items-center justify-end">
                  <MinusCircleOutlined
                    className="mb-2"
                    onClick={() => remove(name)}
                  />
                </div>
                <Form.Item
                  {...restField}
                  name={[name, "question"]}
                  rules={[
                    {
                      required: true,
                      message: "Enter Question",
                    },
                  ]}
                >
                  <Input placeholder="Enter Question" className="w-full" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "answer"]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" />
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add More Question
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <BannerUploader imageUrl={imageUrl} setImageUrl={setImageUrl} />
    </EditFormModal>
  );
};

export default FAQModal;
