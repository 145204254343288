import React, { useEffect, useState } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row, Select, Input, Button } from "antd";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const EditPost = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [postImages, setPostImages] = useState([]);
  const {
    initialValue,
    Edit_Post_Find_Modal,
    categoryList,
    colorList,
    ageList,
    genderList,
  } = useSelector((state) => state.CrudR);
  const callApies = () => {
    dispatch(
      DataGetAction(
        "getCategory",
        { query: "all" },
        "FetchRecord",
        "categoryList"
      )
    );
    dispatch(
      DataGetAction("getColor", { query: "all" }, "FetchRecord", "colorList")
    );
    dispatch(
      DataGetAction("getAge", { query: "all" }, "FetchRecord", "ageList")
    );
    dispatch(
      DataGetAction("getGender", { query: "all" }, "FetchRecord", "genderList")
    );
  };
  useEffect(() => {
    if (initialValue) {
      if (initialValue && initialValue.postImages) {
        setPostImages(initialValue.postImages);
      }
    }
    if (Edit_Post_Find_Modal) {
      form.resetFields();
      callApies();
    }
  }, [Edit_Post_Find_Modal]);
  return (
    <>
      {Edit_Post_Find_Modal && initialValue && (
        <EditFormModal
          form={form}
          apiName="editPost"
          recordName="findPetList"
          requestType="EditRecord"
          heading="Edit Sale Post"
          modalName={"Edit_Post_Find_Modal"}
          initialData={initialValue}
          width={"700px"}
          extraValueObj={{ profileImageUrl: postImages }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input className="capitalize" />
          </Form.Item>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item
                name="postType"
                label="Post Type"
                rules={[{ required: true, message: "Post Type is required" }]}
              >
                <Select className="capitalize">
                  <Option value="find">Find Pet</Option>
                  <Option value="adopt">Adopt Pet</Option>
                  <Option value="sale">Sale Pet</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="categoryId"
                label="Category"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select className="capitalize">
                  {categoryList &&
                    categoryList.map((item) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item
                name="ageId"
                label="Age"
                rules={[{ required: true, message: "Age is required" }]}
              >
                <Select className="capitalize" placeholder="Select Age">
                  {ageList &&
                    ageList.map((item) => (
                      <Option value={item._id}>{item.age}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="breed"
                label="Breed"
                rules={[{ required: true, message: "Breed is required" }]}
              >
                <Input placeholder="Enter Breed" />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item
                name="colorId"
                label="Color"
                rules={[{ required: true, message: "Color is required" }]}
              >
                <Select placeholder="Select Color" className="capitalize">
                  {colorList &&
                    colorList.map((item) => (
                      <Option value={item._id}>{item.colorName}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="genderId"
                label="Gender"
                rules={[{ required: true, message: "Gender is required" }]}
              >
                <Select placeholder="Select Gender" className="capitalize">
                  {genderList &&
                    genderList.map((item) => (
                      <Option value={item._id}>{item.genderType}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item label="Attachment">
            {/* <EditAttachments
              setPostImages={setPostImages}
              postImages={postImages}
            /> */}
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Description is required" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.List name="postLinks">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <div className="flex justify-end w-full mb-1">
                      <MinusCircleOutlined
                        style={{ color: "red" }}
                        onClick={() => remove(name)}
                      />
                    </div>
                    <Row>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="md:pe-3"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                          ]}
                        >
                          <Input className="w-full" placeholder="Enter name" />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "link"]}
                          rules={[
                            {
                              required: true,
                              message: "Link is required",
                            },
                          ]}
                        >
                          <Input className="w-full" placeholder="Enter link" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </EditFormModal>
      )}
    </>
  );
};

export default EditPost;
