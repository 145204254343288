import { Col, Row } from "antd";
import React, { useEffect } from "react";
import TotalServiceAndOrderCard from "./components/TotalServiceAndOrderCard";
import Widget from "../../components/Widget";
import GraphHeader from "./components/GraphHeader";
import CustomShapeBarChart from "./components/CustomShapeBarChart";
import BiaxialBarChart from "./components/BiaxialBarChart";
import AreaGraph from "./components/AreaGraph";
import GraphCards from "./components/GraphCards";
import TablesData from "./components/TablesData";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const { totalServices, totalOrders, totalSale, ProductsSalesGraph, BookingSalesGraph, dailyActiveUser, menuCardData } = useSelector(
    (state) => state.CrudR
  );
  useEffect(() => {
    dispatch(DataGetAction("getTotalServices", "", "FetchRecord", "totalServices"));
    dispatch(DataGetAction("getTotalOrder", "", "FetchRecord", "totalOrders"));
    dispatch(DataGetAction("getTotalSale", "", "FetchRecord", "totalSale"));
    getProductsSales();
    getBookingSales();
    getDailyActiveUser();
  }, []);

  const getProductsSales = (e = "weekly") => {
    dispatch(DataGetAction("getProductSale", { graphType: e }, "FetchRecord", "ProductsSalesGraph"));
  };
  const getBookingSales = (e = "weekly") => {
    dispatch(DataGetAction("getBookingSale", { graphType: e }, "FetchRecord", "BookingSalesGraph"));
  };
  const getDailyActiveUser = (e = "weekly") => {
    dispatch(DataGetAction("getDailyActiveUser", { graphType: e }, "FetchRecord", "dailyActiveUser"));
  };
  return (
    <div>
      <Row>
        <Col xl={5} lg={6} md={8} sm={24} xs={24} className="md:pe-3">
          <TotalServiceAndOrderCard totalServices={totalServices} totalOrders={totalOrders} menuCardData={menuCardData}/>
        </Col>
        <Col xl={9} lg={8} md={16} sm={24} xs={24} className="lg:pe-3">
          <Widget className="mb-4">
            <GraphHeader title={"Daily Active Users"} onChange={(graphType) => getDailyActiveUser(graphType)} />
            <CustomShapeBarChart data={dailyActiveUser} />
          </Widget>
          <Widget className="mb-4">
            <GraphHeader onChange={(e) => getProductsSales(e)} title={"Product Sales"} />
            <AreaGraph ProductsSalesGraph={ProductsSalesGraph} />
          </Widget>
          <Widget>
            <GraphHeader
              title={"Booking"}
              onChange={(e) => {
                console.log("===== graph type =====", e);
                getBookingSales(e);
              }}
            />
            <BiaxialBarChart BookingSalesGraph={BookingSalesGraph} />
          </Widget>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <GraphCards totalSale={totalSale} />
          <TablesData />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
