import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { AiFillDelete } from "react-icons/ai";
const MultipalUploader = ({ imageUrl, setImageUrl }) => {
  const [loading, setLoading] = useState(false);
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      if (info.file.response.url) {
        setImageUrl((olddata) => [...olddata, info.file.response.url.location]);
      }
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onRemoveImageFormList = (index) => {
    setImageUrl([
      ...imageUrl.slice(0, index),
      ...imageUrl.slice(index + 1, imageUrl.length),
    ]);
  };
  return (
    <div>
      <Upload
        multiple={true}
        name="file"
        listType="picture-card"
        className="banner-uploader"
        showUploadList={false}
        action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload>
      <div>
        {imageUrl &&
          imageUrl.map((item, index) => {
            return (
              <div
                className="flex items-center justify-between mb-2 p-1"
                style={{ border: "1px dashed black", borderRadius: "10px" }}
              >
                <div className="flex items-center gap-2">
                  <img
                    alt=""
                    className="rounded"
                    src={item}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>File # {index + 1}</span>
                </div>
                <AiFillDelete
                  fill="red"
                  size={20}
                  onClick={() => onRemoveImageFormList(index)}
                  className="me-4"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MultipalUploader;
