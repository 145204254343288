import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import SubCategoryForm from "./SubCategoryForm";

const EditCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_SubCategory_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_SubCategory_MODAL) {
      form.resetFields();
    }
  }, [EDIT_SubCategory_MODAL]);
  return (
    <>
      {EDIT_SubCategory_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="editSubCategories"
          recordName="productSubCategoryList"
          requestType="EditRecord"
          heading="Edit Sub Category"
          modalName={"EDIT_SubCategory_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <SubCategoryForm form={form} />
        </EditFormModal>
      )}
    </>
  );
};

export default EditCategory;
