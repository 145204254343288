import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import EditCategoryForm from "./editCategoryForm";

const EditCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, Edit_ProductLinking_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (Edit_ProductLinking_Modal) {
      form.resetFields();
    }
  }, [Edit_ProductLinking_Modal]);
  return (
    <>
      {Edit_ProductLinking_Modal && initialValue && (
        <EditFormModal
          form={form}
          method="POST"
  
          apiName="addCategoriesLinking"
          recordName="CategoryLinkList"
          requestType="EditRecord"
          heading="Edit Linking"
          modalName={"Edit_ProductLinking_Modal"}
          initialData={initialValue}
          width={"500px"}
        >
          <EditCategoryForm form={form} />
        </EditFormModal>
      )}
    </>
  );
};

export default EditCategory;
