import React, { useEffect } from "react";
import Table from "../../../components/Table";
import EditSubCategory from "./components/EditSubCategory";
import TableHeader from "./components/TableHeader";
import ShowModal from "../../../components/Modal/ShowModal";
import Action from "../../../components/Table/components/Action";
import Widget from "../../../components/Widget";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const { addRecordSuccess } = useSelector((state) => state.CrudR);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DataGetAction("categoriesList", { query: "all", type: "product" }, "FetchRecord", "parentCategory"));
    dispatch(DataGetAction("subCategories", { query: "all", type: "product" }, "FetchRecord", "subCategory"));
  }, [addRecordSuccess]);

  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName="Edit_ProductLinking_Modal" name="Edit" record={record} />
      </div>
    );
  };

  const renderSubCategory = (data) => {
    if (data && data.subCategories && data.subCategories.length > 0) {
      return data.subCategories.map((Item) => {
        return <span>{Item.name}</span>;
      });
    }
  };
  const columns = [
    {
      title: "Main Category",
      render: (record) => <span className="capitalize">{record.name}</span>,
      key: "name",
      width: "250px",
    },
    {
      title: "Sub Categories",
      render: (record) => <div className="flex flex-row gap-3">{renderSubCategory(record)}</div>,
      key: "subCategoryName",
      width: "250px",
    },

    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg" style={{ border: "1px solid" }}>
      <Widget className="p-5">
        <TableHeader />
        <Table
          apiName="categoriesLinkList"
          recordName="CategoryLinkList"
          columns={columns}
          customQuery={{ type: "product" }}
        />
      </Widget>
      <EditSubCategory />
    </div>
  );
};
export default Index;
