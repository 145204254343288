import React from "react";
import SearchBox from "../../../components/Filters/SearchBox";
import DateFilter from "../../../components/Filters/DateFilter";
import SelectedFilter from "../../../components/Filters/SelectedFilter";
import TableTitle from "../../../components/Table/components/TableTitle";
import AddBroadcastMessage from "./AddBroadcastMessage";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-between flex-wrap mb-5" style={{ borderBottom: "2px solid #E6E6E6" }}>
      <TableTitle title="Broadcast Message" />
      <AddBroadcastMessage />
    </div>
  );
};

export default TableHeader;
