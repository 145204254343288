import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import ColorForm from "./ColorForm";

const AddColor = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      form={form}
      modalName='Add_Color_Modal'
      heading="Add Colour"
      apiName="addColor"
      recordName="colorList"
      width={"500px"}
      addButtonName="Add Colour"
    >
      <ColorForm />
    </AddFormModal>
  );
};

export default AddColor;
