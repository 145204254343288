import React from "react";
import DedailModal from "../../../components/Modal/DedailModal";
import { Avatar } from "antd";
import { useSelector } from "react-redux";

const ViewUsers = () => {
  const { initialValue } = useSelector((state) => state.CrudR);
  const renderUser = () => {
    if (
      initialValue &&
      initialValue.userDetails &&
      initialValue.userDetails.length > 0
    ) {
      return initialValue.userDetails.map((item) => {
        return (
          <div
            className="flex p-2 w-fit items-center"
            style={{
              gap: "6px",
              border: "1px solid #CCCCCC",
              borderRadius: "10px",
            }}
          >
            <Avatar size="large" src={item.profileImageUrl} />
            <div className="flex flex-col">
              <span>{item.fullName}</span>
              <span>{item.email}</span>
            </div>
          </div>
        );
      });
    }
  };
  return (
    <DedailModal
      modalName={"View_User_Notification_Modal"}
      heading="View Users"
      width="500px"
    >
      <div className="flex items-center gap-2 flex-wrap">{renderUser()}</div>
    </DedailModal>
  );
};

export default ViewUsers;
