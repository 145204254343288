import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import moment from "moment";
import BannerUploader from "../../../components/Uploader/bannerUploader";
const UserForm = ({ setProfileImage, profileImage, formType, type }) => {
  const renderInputs = () => {
    return (
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: "Start Date is required",
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "270px" }}
              disabledDate={(current) => current.isBefore(moment().subtract(1, "day"))}
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[
              {
                required: true,
                message: "End Date is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("startDate") <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The end date is equal to or greater than to start date");
                },
              }),
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "270px" }}
              disabledDate={(current) => {
                current.isBefore(moment().subtract(1, "day"));
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        >
          <Input placeholder="Enter your title" />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          name="orderNo"
          label="Order Number"
          rules={[
            {
              required: true,
              message: "Order number is required",
            },
          ]}
        >
          <Input type="number" placeholder="Enter order number" />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="pe-3">
        <Form.Item
          name="hyperLink"
          label="Hyper Link"
          rules={[
            {
              required: true,
              message: "Hyper link is required",
            },
          ]}
        >
          <Input placeholder="Enter hyper link" />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} >
        <Form.Item
          name="bannerType"
          label="Banner Type"
          rules={[
            {
              required: true,
              message: "Banner type is required",
            },
          ]}
        >
          <Select placeholder="Enter banner type">
            <Select.Option value="web">Web</Select.Option>
            <Select.Option value="mobile">Mobile</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {formType !== "add" ? <>{type === "custom" && renderInputs()}</> : renderInputs()}
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="bannerType" label="Banner Type">
          <Select
            defaultValue="app"
            options={[
              {
                value: "app",
                label: "App",
              },
              {
                value: "web",
                label: "Web",
              },
            ]}
          />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="description" label="Description">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="pt-2">
        <span className="font-bold ">Upload Image (382 X 130)</span>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="w-full pt-3">
        <BannerUploader imageUrl={profileImage} setImageUrl={setProfileImage} />
      </Col>
    </Row>
  );
};

export default UserForm;
