import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import CategoryForm from "./CategoryForm";

const EditCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_CATEGORY_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_CATEGORY_MODAL) {
      form.resetFields();
    }
  }, [EDIT_CATEGORY_MODAL]);
  return (
    <>
      {EDIT_CATEGORY_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="editCategory"
          recordName="categoryList"
          requestType="EditRecord"
          heading="Edit Category"
          modalName={"EDIT_CATEGORY_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <CategoryForm />
        </EditFormModal>
      )}
    </>
  );
};

export default EditCategory;
