import { LoginSuccess } from "./CommonFn";

const initialState = {
  role: "superAdmin",
  token: localStorage.getItem("token"),
  authUser: JSON.parse(localStorage.getItem("userInfo")),
  loginSuccess: false,
  loginError: false,
  loginSpin: false,
  logoutSuccess: false,
  profileSpin: false,
  updateSpin: false,
  show_UnAuthorized: false,
};
/* eslint-disable */
export default (state = initialState, action) => {
  switch (action.type) {
    case "Login_SUCCESS": {
      console.log("===== state.role",state.role)
      let Record = LoginSuccess(action.payload.user, state.role);
      return {
        ...state,
        authUser: Record,
        loginSuccess: Record !== null ? true : false,
        loginSpin: false,
      };
    }
    case "Login_ERROR": {
      return {
        ...state,
        loginError: true,
        loginSpin: false,
      };
    }
    case "LoginSpin_START": {
      return {
        ...state,
        loginSpin: true,
      };
    }
    case "Log_out": {
      console.log("=== call =====");
      localStorage.clear();
      return {
        ...state,
        authUser: null,
        logoutSuccess: state.authUser === null ? true : false,
      };
    }
    case "GET_PROFILE_SUCCESS": {
      return {
        ...state,
        authUser: action.payload.Record,
        profileSpin: false,
      };
    }
    case "GET_PROFILE_ERROR": {
      return {
        ...state,
        profileSpin: false,
      };
    }
    case "PROFILE_SPIN_START": {
      return {
        ...state,
        profileSpin: true,
      };
    }
    case "UPDATE_PROFILE_SUCCESS": {
      let localData = JSON.parse(localStorage.getItem("userInfo"));
      let updateData = action.payload.Record;
      localData["fullName"] = updateData.fullName;
      localData["profileImageUrl"] = updateData.profileImageUrl;
      localStorage.setItem("userInfo", JSON.stringify(localData));
      return {
        ...state,
        authUser: action.payload.Record,
        updateSpin: false,
      };
    }
    case "UPDATE_PROFILE_ERROR": {
      return {
        ...state,
        updateSpin: false,
      };
    }
    case "UPDATE_PROFILE_SPIN_START": {
      return {
        ...state,
        updateSpin: true,
      };
    }
    case "UN_AUTHORIZED": {
      return {
        ...state,
        show_UnAuthorized: true,
      };
    }
    case "Hide_UN_AUTHORIZED": {
      return {
        ...state,
        show_UnAuthorized: false,
      };
    }
    case "RESET_AUTH_STATE": {
      return {
        ...state,
        loginSuccess: false,
        loginError: false,
        loginSpin: false,
        logoutSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};
