import React from "react";
import SearchBox from "../../../../components/Filters/SearchBox";
import DateFilter from "../../../../components/Filters/DateFilter";
import { Button, message } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const TableHeader = () => {
  const { ordersRefundList } = useSelector((state) => state.CrudR);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    if (csvData && csvData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      message.info("Data is not found");
    }
  };

  return (
    <div className="flex items-center justify-end flex-wrap mb-3 gap-3">
      <SearchBox
        placeholder="OrderId / Customer/Merchant name/email"
        apiName="getRefundOrders"
        recordName="ordersRefundList"
        width="400px"
      />
      {/* <DateFilter width="300px" apiName="refundOrders" recordName="ordersList" customeQuery={{query:'cancelled'}} /> */}
      <Button
        onClick={(e) => exportToCSV(ordersRefundList, "RefundOrder")}
        className="drop-shadow bg-primary-brown"
        type="primary"
      >
        Excel download
      </Button>
    </div>
  );
};

export default TableHeader;
