import React, { useState } from "react";
import NewsForm from "./NewsForm";
import { Form } from "antd";
import AddFormModal from "../../../components/Modal/AddFormModal";

const AddNews = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState([]);
  return (
    <AddFormModal
      form={form}
      modalName="Add_News_Modal"
      heading="Add News"
      apiName="addNews"
      recordName="newsList"
      width={"600px"}
      addButtonName="Add News"
      extraValueObj={{ fileUrl: profileImage }}
    >
      <NewsForm
        setProfileImage={setProfileImage}
        profileImage={profileImage}
      />
    </AddFormModal>
  );
};

export default AddNews;
