import React, { useEffect, useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import NewsForm from "./NewsForm";
import { Form } from "antd";

const EditNews = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState([]);
  const { initialValue, Edit_News_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (initialValue) {
      if (initialValue && initialValue.fileUrl) {
        setProfileImage(initialValue.fileUrl);
      }
    }
    if (Edit_News_Modal) {
      form.resetFields();
    }
  }, [Edit_News_Modal]);
  return (
    <>
      {Edit_News_Modal && initialValue && (
        <EditFormModal
          form={form}
          apiName="editNews"
          recordName="newsList"
          requestType="EditRecord"
          heading="Edit News"
          modalName={"Edit_News_Modal"}
          initialData={initialValue}
          width={"600px"}
          extraValueObj={{ fileUrl: profileImage }}
        >
          <NewsForm
            setProfileImage={setProfileImage}
            profileImage={profileImage}
          />
        </EditFormModal>
      )}
    </>
  );
};

export default EditNews;
