import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import AgeForm from "./AgeForm";

const AddAge = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName='Add_Age_Modal'
      heading="Add Age"
      apiName="addAge"
      recordName="ageList"
      width={"500px"}
      addButtonName="Add Age"
    >
      <AgeForm />
    </AddFormModal>
  );
};

export default AddAge;
