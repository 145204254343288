import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import GenderForm from "./GenderForm";

const AddGender = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName='Add_Gender_Modal'
      heading="Add Gender"
      apiName="addGender"
      recordName="genderList"
      width={"500px"}
      addButtonName="Add Gender"
    >
      <GenderForm />
    </AddFormModal>
  );
};

export default AddGender;
