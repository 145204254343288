import React from "react";
import ListedList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import { Avatar } from "antd";

import DeleteModal from "../../components/Modal/DeleteModal";
import Action from "../../components/Table/components/Action";
import { renderStatus } from "../../utils/CommonFunctions";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Index = () => {
  const { newBooking } = useSelector((state) => state.CrudR);
  const location = useLocation();
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {record.refundStatus === "inReview" && (
          <DeleteModal
            method="PUT"
            apiName="refundBooking"
            recordName="bookingList"
            requestType="EditRecord"
            title="Refund"
            content="Are you sure you want to refund this amount"
            selectedItem={{ _id: record._id, refundStatus: "approve" }}
            buttonName="Refund"
          />
        )}
      </div>
    );
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.bookingId}</span>,
      key: "serviceId",
      width: "80px",
    },
    {
      title: "User Name",
      render: (record) => {
        const { userInfo } = record;
        return (
          <span className="flex items-center gap-2 capitalize">
            <Avatar shape="square" size={70} src={userInfo?.profileImageUrl} />
            <div>
              <p>{userInfo?.fullName || "---"}</p>
              <p>{userInfo?.email || "---"}</p>
              <p>{userInfo?.phoneNumber || "---"}</p>
            </div>
          </span>
        );
      },
      key: "username",
    },
    {
      title: "Merchant Name",
      render: (record) => {
        const { merchantInfo } = record;
        return (
          <span className="flex items-center gap-2 capitalize">
            <Avatar shape="square" size={70} src={merchantInfo?.profileImageUrl} />
            <div>
              <p>{merchantInfo?.fullName || "---"}</p>
              <p>{merchantInfo?.email || "---"}</p>
              <p>{merchantInfo?.phoneNumber || "---"}</p>
            </div>
          </span>
        );
      },
      key: "merchantInfo",
    },
    {
      title: "Service Name",
      render: (record) => {
        const { serviceInfo } = record;
        return <span>{serviceInfo?.serviceName || "---"}</span>;
      },
      key: "serviceName",
    },
    {
      title: "Price",
      render: (record) => <span>${record.price || "---"}</span>,
      key: "totalPrice",
    },
    {
      title: "Booking Date/Time",
      render: (record) => (
        <div className="flex flex-col gap-1">
          <span>{moment(record.selectedDate).format("DD MMM, YYYY") || "---"}</span>
          <span>{record.selectedSlot}</span>
        </div>
      ),
      key: "date",
    },

    {
      title: "Confirm Date",
      render: (record) => (
        <span>{(record?.confirmedAt && moment(record.confirmedAt).format("DD MMM, YYYY")) || "---"}</span>
      ),
      key: "date",
    },

    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => {
        record.refundStatus === "inReview" && <Action>{renderAction(record)}</Action>;
      },
      key: "action",
      width: "50px",
    },
  ];

  return (
    <div className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg" style={{ border: "1px solid" }}>
      <Widget className="p-5">
        <TableHeader  userId={location.state?.userId}/>
        <ListedList
          columns={columns}

          apiName="getBookingList"
          recordName="bookingList"
          customQuery={{ list: "status", userId: location.state?.userId }}
          customCondition={newBooking}
        />
      </Widget>
    </div>
  );
};

export default Index;
