import { Form, Input } from "antd";
import React from "react";

const ColorForm = () => {
  return (
    <Form.Item
      name="colorName"
      label="Colour"
      rules={[
        {
          required: true,
          message: "Colour is required",
        },
      ]}
    >
      <Input placeholder={"Enter your colour"} />
    </Form.Item>
  );
};

export default ColorForm;
