import React from "react";
import OrderList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import { capitalize, renderStatus } from "../../utils/CommonFunctions";
import { Avatar } from "antd";
import placeholder from "../../assets/images/placeholder.webp";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../components/Table/components/Action";
import DeleteModal from "../../components/Modal/DeleteModal";
import ServiceDetail from "./components/ServiceDetail";
import { useLocation } from "react-router-dom";
import { Button, Flex, Tooltip } from "antd";
import { EyeOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const renderAction = (record) => {
    return (
      <div className="flex flex-row justify-between gap-4">
        <Button
          className="cursor-pointer"
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
          onClick={() => {
            dispatch({
              type: "Show_Modal",
              modalName: "ViewServiceModal",
              payload: record,
            });
          }}
        />
        {record.status === "active" && (
          <DeleteModal
            showButton={true}
            icon={<CloseOutlined />}
            method="PUT"
            buttonClass="bg-red-500"
            apiName="changeService"
            recordName="servicesList"
            requestType="EditRecord"
            title="Block"
            content="Are you sure you want to block this record"
            selectedItem={{ _id: record._id, status: "block" }}
            buttonName="Block"
            danger={true}
          />
        )}
        {record.status === "block" && (
          <DeleteModal
          showButton={true}
            method="PUT"
            icon={<CheckOutlined />}
            apiName="changeService"
            recordName="servicesList"
            requestType="EditRecord"
            title="Active"
            content="Are you sure you want to active this record"
            selectedItem={{ _id: record._id, status: "active" }}
            buttonName="Active"
          />
        )}
        
      </div>
    );
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.serviceId}</span>,
      key: "serviceId",
      width: "80px",
    },
    {
      title: "User Name",
      render: (record) => {
        const { userInfo } = record;
        return (
          <span className="flex items-center gap-2 capitalize">
            <Avatar shape="square" size={70} src={userInfo?.profileImageUrl} />
            <div>
              <p>{userInfo?.fullName || "---"}</p>
              <p>{userInfo?.email || "---"}</p>
              <p>{userInfo?.phoneNumber || "---"}</p>
            </div>
          </span>
        );
      },
      key: "username",
    },
    {
      title: "Service Name",
      render: (record) => <span>{record?.serviceName || "---"}</span>,
      key: "serviceName",
    },
    {
      title: "Service Category",
      render: (record) => {
        const { categoryInfo } = record;
        return <span>{capitalize(categoryInfo?.name) || "---"}</span>;
      },
      key: "serviceCategory",
    },
    {
      title: "Total Price",
      render: (record) => <span>${record.price || "---"}</span>,
      key: "totalPrice",
    },
    {
      title: "Created Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("DD MMM, YYYY") || "---"}</span>
      ),
      key: "date",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },

    {
      title: "",
      render: (record) => (
        <div className="flex flex-row justify-between">
          {renderAction(record)}
        </div>
      ),
      key: "action",
      width: "50px",
    },
  ];
  let data = [
    {
      userId: 1,
      username: "ammad",
      fullName: "Ammad Arshad",
      email: "ammadarshad@gmail.com",
      phoneNumber: "923173035924",
      orders: 1,
      booking: 1,
      price: 12,
      createdAt: new Date(),
      status: "active",
    },
  ];
  return (
    <Widget className="p-5">
      <TableHeader userId={location.state?.userId} />
      <OrderList
        columns={columns}
        tempData={data}
        apiName="getServicesList"
        recordName="servicesList"
        customQuery={{ userId: location.state?.userId }}
      />
      <ServiceDetail />
    </Widget>
  );
};

export default Index;
