import React from "react";
import SearchBox from "../../../../components/Filters/SearchBox";
import DateFilter from "../../../../components/Filters/DateFilter";
import SelectedFilter from "../../../../components/Filters/SelectedFilter";
import { Button } from "antd";
import TableTitle from "../../../../components/Table/components/TableTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TableHeader = ({userId}) => {
  const { productList } = useSelector((state) => state.CrudR);
  const navigate = useNavigate();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  let options = [
    { label: "All", value: "all" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Approved", value: "approved" },
    { label: "Initated", value: "initated" },
    { label: "In Process", value: "inProcess" },
    { label: "Deliverd", value: "deliverd" },
  ];
  return (
    <div className="flex items-center justify-end flex-wrap mb-3 gap-3">
      <SearchBox
        placeholder="Id / Product name"
        apiName="getProduct"
        recordName="productList"
        customeQuery={{userId: userId}}
      />
      {/* <SelectedFilter
        placeholder="Filter by category"
        width="150px"
        options={options}
        apiName="getOrders"
        recordName="ordersList"
      /> */}
      <DateFilter apiName="getProduct" recordName="productList"  customeQuery={{userId: userId}}/>
      {userId && <Button
          onClick={() => {
            navigate('/petProductPosting', { state: { userId: null } })
            window.location.reload()
          }}
          className="drop-shadow bg-primary-brown"
          type="primary"
        >
          Show All
        </Button>}
      <Button
        onClick={(e) => exportToCSV(productList, "productList")}
        className="drop-shadow bg-primary-brown"
        type="primary"
      >
        Excel download
      </Button>
    </div>
  );
};

export default TableHeader;
