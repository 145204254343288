import React, { useEffect } from "react";
import OrderList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import { renderStatus } from "../../utils/CommonFunctions";
import { Avatar } from "antd";
import placeholder from "../../assets/images/placeholder.webp";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailModal from "./components/ProductDetailModal";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { useLocation } from "react-router-dom";

const Index = () => {
  const dispatch = useDispatch();
  const { orderList, orderFetch } = useSelector((state) => state.CrudR);
  const location = useLocation();
  console.log("==location", location);

  const renderProductName = (record) => {
    if (record && record.products && record.products.length > 0) {
      return (
        <span
          className="text-[#4791FF] capitalize underline cursor-pointer"
          onClick={() =>
            dispatch({
              type: "Show_Modal",
              modalName: "Order_Product_Detail",
              payload: record.products,
            })
          }
        >
          View
        </span>
      );
    } else {
      return "----";
    }
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.orderId}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "User Name",
      render: (record) => {
        let userDetail = record && record.userDetails[0];
        return (
          <span className="capitalize flex items-center gap-2">
            <Avatar src={record.profileImageUrl || placeholder} />
            {(userDetail && userDetail.fullName) || "---"}
          </span>
        );
      },
      key: "username",
    },
    {
      title: "Products",
      render: (record) => renderProductName(record),
      key: "productDetail",
    },

    {
      title: "Total Price",
      render: (record) => <span>${record.totalPrice || "---"}</span>,
      key: "totalPrice",
    },
    {
      title: "Date",
      render: (record) => <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>,
      key: "date",
    },
    {
      title: "Amount Status",
      render: (record) => {
        return <span>{renderStatus(record?.status)}</span>;
      },
      key: "status",
    },
  ];
  let customQuery = {};

  if (location.state?.userId) {
    customQuery.userId = location.state.userId;
  }
  
  if (location.state?.merchantId) {
    customQuery.merchantId = location.state.merchantId;
  }
  
  if (location.state?.key) {
    customQuery = {...customQuery, ...location.state};
  }
    console.log("====== customQuery", customQuery);
  // Now customQuery will correctly reflect the combination of userId, merchantId, and status
  
  

  return (
    <Widget className="p-5">
      <TableHeader userId={location.state?.userId} merchantId={location.state?.merchantId} />
      <OrderList
        columns={columns}
        customQuery={customQuery}
        apiName="getOrderList"
        recordName="orderList"
        customCondition={orderFetch}
      />
      <ProductDetailModal />
    </Widget>
  );
};

export default Index;
