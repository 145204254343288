import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BiaxialBarChart = ({ BookingSalesGraph }) => (
  <ResponsiveContainer width="100%" height={225}>
    <BarChart
      data={BookingSalesGraph}
      margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis dataKey="name" />
      <YAxis domain={[0, (dataMax) => dataMax + 2]} />

      <Bar stackId="left" dataKey="totalPrice" fill="#03275b" />
    </BarChart>
  </ResponsiveContainer>
);

export default BiaxialBarChart;
