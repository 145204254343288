import { Col, Form, Input, Row } from "antd";
import React from "react";
import Upload from "../../../components/Uploader";
const UserForm = ({ setProfileImage, profileImage, formType }) => {
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          name="username"
          label="User Name"
          rules={[{ required: true, message: "user name is required" }]}
        >
          <Input placeholder="Enter your user name" />
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[{ required: true, message: "full name is required" }]}
        >
          <Input placeholder="Enter your full name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "email is required" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[{ required: true, message: "phone number is required" }]}
        >
          <Input placeholder="Enter your phone number" />
        </Form.Item>
      </Col>
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        className="flex items-center justify-center"
      >
        <Upload imageUrl={profileImage} setImageUrl={setProfileImage} />
      </Col>
      {formType !== "edit" && (
        <>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input.Password placeholder="Enter Password" />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:ps-3">
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Confirm password is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password is not match");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter Confirm Password" />
          </Form.Item>
          </Col>
        </>
      )}
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Enter your description" />
        </Form.Item>
        <Form.Item name="shortDescription" label="Short Description">
          <Input.TextArea placeholder="Enter your short description" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default UserForm;
