// import { Form, Input } from "antd";
// import React from "react";
// import CategoryImageUploader from "../../../../components/Uploader/CategoryImageUploader";

// const CategoryForm = ({ setImageUrl }) => {
//   return (
//     <>
//       <Form.Item
//         name="name"
//         label="Category"
//         rules={[
//           {
//             required: true,
//             message: "Category is required",
//           },
//         ]}
//       >
//         <Input placeholder={"Enter your pet category"} />
//       </Form.Item>
//       <div className="mb-5">
//       <CategoryImageUploader setImageUrl={setImageUrl} />
//       </div>
//     </>
//   );
// };

// export default CategoryForm;


import { Form, Input } from "antd";
import React from "react";
import CategoryImageUploader from "../../../../components/Uploader/CategoryImageUploader";

const CategoryForm = ({ setImageUrl, reset }) => {
  return (
    <>
      <Form.Item
        name="name"
        label="Category"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
      >
        <Input placeholder={"Enter your pet category"} />
      </Form.Item>
      <div className="mb-5">
        <CategoryImageUploader setImageUrl={setImageUrl} reset={reset} />
      </div>
    </>
  );
};

export default CategoryForm;
