import React from "react";
import DedailModal from "../../../components/Modal/DedailModal";
import { useSelector } from "react-redux";

const ViewNotification = () => {
  const { initialValue } = useSelector((state) => state.CrudR);
  return (
    <DedailModal
      modalName={"View_Notification_Modal"}
      heading="View Notification"
      width="500px"
    >
      {initialValue && initialValue.notificationText}
    </DedailModal>
  );
};

export default ViewNotification;
