import { Button, Form, Input, Select, Upload } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";

const EditCategoryForm = ({ form }) => {
  const dispatch = useDispatch();
  const { parentCategory, subCategory } = useSelector((state) => state.CrudR);

  useEffect(() => {
    dispatch(DataGetAction("subCategories", { query: "all", type: "product" }, "FetchRecord", "subCategory"));
  }, []);

  const renderSubCategoryOption = () => {
    if (subCategory && subCategory.length > 0) {
      return subCategory.map((item) => {
        return <Select.Option value={item._id}>{item.name}</Select.Option>;
      });
    }
  };
  return (
    <>
      <Form.Item
        name="subCategory"
        label="Sub Category"
        rules={[
          {
            required: true,
            message: "Sub Category is required",
          },
        ]}
      >
        <Select placeholder="Select Sub Category" mode="multiple">
          {renderSubCategoryOption()}
        </Select>
      </Form.Item>
    </>
  );
};

export default EditCategoryForm;
