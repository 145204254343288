import React from "react";
import ListedList from "../../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../../components/Widget";
import moment from "moment";
import { Avatar } from "antd";
import placeholder from "../../../assets/images/placeholder.webp";
import Action from "../../../components/Table/components/Action";
import { useDispatch, useSelector } from "react-redux";
import { renderStatus } from "../../../utils/CommonFunctions";
import DisputeModal from "./components/DisputeModal";
import DeleteModalMessage from "../../../components/Modal/DeleteModalMessage";
const Index = () => {
  const { ordersRefundList, disputeMessage } = useSelector((state) => state.CrudR);
  console.log("====== ordersList =====", disputeMessage);
  const dispatch = useDispatch();
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {(record.refundStatus !== "refunded") && (
          <DeleteModalMessage
            method="PUT"
            apiName="makeOrderRefunded"
            recordName="ordersRefundList"
            requestType="EditRecord"
            title="Refund"
            content="Are you sure you want to refund this amount."
            selectedItem={{ _id: record._id, productId: record.productId, refundStatus: "approved" }}
            buttonName="Refund"
          />
        )}
      </div>
    );
  };

  const openDisputeModal = (item) => {
    dispatch({
      type: "Show_Modal",
      modalName: "DisputeModal",
      dynamicValueName: "DisputeModal",
      payload: item,
    });
  };

  const viewDispute = (item) => {
    if (item && item?.dispute?.length > 0) {
      return (
        <span className="hover:underline cursor-pointer" onClick={() => openDisputeModal(item)}>
          View
        </span>
      );
    } else {
      return <span>---</span>;
    }
  };

  let columns = [
    {
      title: "OrderID",
      render: (record) => <span>{record.orderId}</span>,
      key: "orderId",
      width: "80px",
    },
    {
      title: "Customer Name",
      render: (record) => {
        const { userInfo } = record;
        return (
          <span className="flex items-center gap-2 capitalize">
            <Avatar shape="square" size={70} src={userInfo?.profileImageUrl} />
            <div>
              <p>{userInfo?.fullName || "---"}</p>
              <p>{userInfo?.email || "---"}</p>
              <p>{userInfo?.phoneNumber || "---"}</p>
            </div>
          </span>
        );
      },
      key: "username",
    },
    {
      title: "Merchant Name",
      render: (record) => {
        const { merchantInfo } = record;
        return (
          <span className="flex items-center gap-2 capitalize">
            <Avatar shape="square" size={70} src={merchantInfo?.profileImageUrl} />
            <div>
              <p>{merchantInfo?.fullName || "---"}</p>
              <p>{merchantInfo?.email || "---"}</p>
              <p>{merchantInfo?.phoneNumber || "---"}</p>
            </div>
          </span>
        );
      },
      key: "merchantInfo",
    },

    {
      title: "Item Name",
      render: (record) => <span>{record.productName || "---"}</span>,
      key: "productName",
    },

    {
      title: "quantity",
      render: (record) => <span>{record.quantity || "---"}</span>,
      key: "quantity",
    },
    {
      title: "Unit Price",
      render: (record) => <span>${record.unitPrice || "---"}</span>,
      key: "unitPrice",
    },
    {
      title: "Shipment fee",
      render: (record) => <span>${record?.shipmentMethod?.unitPrice || "---"}</span>,
      key: "unitPrice",
    },
    {
      title: "Total Price",
      render: (record) => <span>${record.totalPrice || "---"}</span>,
      key: "unitPrice",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record?.status)}</span>,
      key: "status",
    },
    {
      title: "Return Reason",
      render: (record) => <span>{record?.returnReason}</span>,
      key: "Reason",
    },
    {
      title: "Dispute",
      render: (record) => <span>{viewDispute(record)}</span>,
      key: "Reason",
    },
    {
      title: "Refund Status",
      render: (record) => <span>{renderStatus(record?.refundStatus)}</span>,
      key: "Rstatus",
    },
    {
      title: "Cancelled At",
      render: (record) => (
        <span>
          {(record?.cancelledAt && moment(record?.cancelledAt).format(process.env.REACT_APP_DATE_FORMATE)) || "-----"}
        </span>
      ),
      key: "cancelledAt",
    },
    {
      title: "",
      render: (record) => {
        if (record?.refundStatus !== "refunded") {
          return <Action>{renderAction(record)}</Action>;
        }
      },
      key: "action",
      width: "50px",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <ListedList
          columns={columns}
          apiName="getRefundOrders"
          recordName="ordersRefundList"
          customCondition={disputeMessage}
          scroll={{
            x: 1800,
          }}
        />
      </Widget>
      <DisputeModal />
    </>
  );
};

export default Index;
