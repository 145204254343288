import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import ServicesSubCategoryForm from "./ServicesSubCategoryForm";

const EditServicesSubCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_Services_SubCategory_MODAL } = useSelector(
    (state) => state.CrudR
  );

  useEffect(() => {
    if (EDIT_Services_SubCategory_MODAL) {
      form.resetFields();
    }
  }, [EDIT_Services_SubCategory_MODAL]);
  return (
    <>
      {EDIT_Services_SubCategory_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="editSubCategories"
          recordName="servicesSubCategoryList"
          requestType="EditRecord"
          heading="Edit Sub Category"
          modalName={"EDIT_Services_SubCategory_MODAL"}
          initialData={initialValue}
          width={"500px"}
          extraValueObj={{ type: "service" }}
        >
          <ServicesSubCategoryForm form={form}/>
        </EditFormModal>
      )}
    </>
  );
};

export default EditServicesSubCategory;
