import { Tabs } from "antd";
import React from "react";
import SalePost from "./SalePost";
import FindPost from "./FindPost";
import AdoptPost from "./AdoptPost";

const index = () => {
  const items = [
    {
      key: "1",
      label: "Sale Pet",
      children: <SalePost />,
    },
    {
      key: "2",
      label: "Find Pet",
      children: <FindPost />,
    },
    {
      key: "3",
      label: "Adopt Pet",
      children: <AdoptPost />,
    },
  ];
  return (
    <Tabs defaultActiveKey="1" type="card" className="h-full" items={items} />
  );
};

export default index;
