import { Tabs } from "antd";
import React, { useState } from "react";
import FAQ from "./FAQ";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermAndCondition from "./TermAndCondition";
import SocialMediaLinks from "./SocialMediaLinks"

const Index = () => {
  const [tabsType, setTabsType] = useState("faq");
  const items = [
    {
      key: "1",
      label: "FAQ",
      children: <FAQ tabsType={tabsType} />,
    },
    {
      key: "2",
      label: "About Us",
      children: <AboutUs tabsType={tabsType} />,
    },
    {
      key: "3",
      label: "Privacy & Policy",
      children: <PrivacyPolicy tabsType={tabsType} />,
    },
    {
      key: "4",
      label: "Terms & Conditions",
      children: <TermAndCondition tabsType={tabsType} />,
    },
    {
      key: "5",
      label: "Social Media Links",
      children: <SocialMediaLinks tabsType={tabsType} />,
    },
  ];
  const onchangeTabs = (e) => {
    if (e === 1) {
      setTabsType("faq");
    } else if (e === 2) {
      setTabsType("aboutUs");
    } else if (e === 3) {
      setTabsType("privacyPolicy");
    } else if (e === 4) {
      setTabsType("termAndCondition");
    } else if (e === 4) {
      setTabsType("socialLinks");
    }
  };

  return (
    <>
      <Tabs onChange={(e) => onchangeTabs(e)} defaultActiveKey="1" type="card" className="h-full" items={items} />
    </>
  );
};

export default Index;
