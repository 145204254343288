import React from "react";
import TableTitle from "../../../../components/Table/components/TableTitle";
import { Button } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const TableHeader = ({ targetRef, title }) => {
  const navigate = useNavigate();
  const { findKeywordsDetail } = useSelector((state) => state.CrudR);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <div className="flex items-center gap-3">
        <IoArrowBackOutline className='cursor-pointer' size={30} onClick={() => navigate(-1)} />
        <TableTitle title={`Keyword Searches: ${title}`} />
      </div>
      <div className="flex items-center gap-2 flex-wrap">
        <Button
          onClick={(e) => exportToCSV(findKeywordsDetail, "Users")}
          className="drop-shadow bg-primary-brown text-[#fff] hover:text-[#fff]"
          size="large"
        >
          Excel download
        </Button>
        <Button
          onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
          className="drop-shadow bg-primary-brown text-[#fff] hover:text-[#fff]"
          size="large"
        >
          PDF download
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
