// import { Button, Upload } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import React from "react";

// const CategoryImageUploader = ({ setImageUrl }) => {
//   const handleChange = (info) => {
//     if (info.file.status === "done") {
//       setImageUrl(info.file.response.url.location);
//     }
//   };
//   return (
//     <Upload
//       action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
//       listType="picture"
//       maxCount={1}
//       onChange={handleChange}
//     >
//       <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
//     </Upload>
//   );
// };

// export default CategoryImageUploader;


import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const CategoryImageUploader = ({ setImageUrl, reset }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (reset) {
      setFileList([]);
      setImageUrl(undefined);
    }
  }, [reset, setImageUrl]);

  const handleChange = (info) => {
    setFileList(info.fileList);
    if (info.file.status === "done") {
      setImageUrl(info.file.response.url.location);
    }
  };

  return (
    <Upload
      action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
      listType="picture"
      maxCount={1}
      onChange={handleChange}
      fileList={fileList}
    >
      <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
    </Upload>
  );
};

export default CategoryImageUploader;
