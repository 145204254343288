import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import AgeForm from "./CategoryForm";

const EditCategory = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_Category_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_Category_MODAL) {
      form.resetFields();
    }
  }, [EDIT_Category_MODAL]);
  return (
    <>
      {EDIT_Category_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="editCategories"
          recordName="productCategoryList"
          requestType="EditRecord"
          heading="Edit Category"
          modalName={"EDIT_Category_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <AgeForm form={form} />
        </EditFormModal>
      )}
    </>
  );
};

export default EditCategory;
