import React from "react";
import AddGender from "./AddGender";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-end flex-wrap mb-3">
      <AddGender />
    </div>
  );
};

export default TableHeader;
