import { Select } from "antd";
import React from "react";

const GraphHeader = ({ title, onChange }) => {
  return (
    <div className="flex justify-between items-center py-2 px-4">
      <h4 className="font-roboto-bold">{title}</h4>
      <Select onChange={onChange} defaultValue={"weekly"}>
        <Select.Option value="weekly">Weekly</Select.Option>
        <Select.Option value="monthly">Monthly</Select.Option>
        <Select.Option value="yearly">Yearly</Select.Option>
      </Select>
    </div>
  );
};

export default GraphHeader;
