

import React, { useState, useEffect } from "react";
import { Modal, Carousel, Empty, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { renderStatus } from "../../../../utils/CommonFunctions";

const Index = () => {
  const dispatch = useDispatch();
  const { View_Product_Modal, initialValue } = useSelector((state) => state.CrudR);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);

  useEffect(() => {
    // Reset selected package and shipping method when the modal is opened
    if (View_Product_Modal && initialValue?.packaging && initialValue.packaging.length > 0) {
      const firstPackage = initialValue.packaging[0];
      setSelectedPackage(firstPackage);
      setShippingMethod(firstPackage.shipment[0]?._id);
    }
  }, [View_Product_Modal, initialValue]);

  const contentStyle = {
    margin: 0,
    width: "400px",
    height: "400px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const renderImageSlider = () => {
    if (initialValue?.uploadImage && initialValue.uploadImage.length > 0) {
      return initialValue.uploadImage.map((url, index) => (
        <div key={index} style={contentStyle}>
          <img
            src={url}
            style={{
              width: "400px",
              height: "400px",
              border: "2px solid black",
              objectFit: "fill",
            }}
            alt={`product-${index}`}
          />
        </div>
      ));
    } else {
      return <Empty description="No Image Uploaded" />;
    }
  };

  const renderPackageDetail = (data) => {
    if (data && data.length > 0) {
      return data.map((Item) => {
        let activeClass = "bg-white ";
        if (selectedPackage && selectedPackage._id === Item._id) {
          activeClass = "bg-[#FFE1B8]";
        }

        return (
          <span
            key={Item._id}
            onClick={() => {
              setSelectedPackage(Item);
              setShippingMethod(Item.shipment[0]?._id);
            }}
            className={`font-medium px-4 py-2 border-2 cursor-pointer rounded border-amber-700 drop-shadow hover:bg-[#FFE1B8] ${activeClass}`}
          >
            {Item.packageName}
          </span>
        );
      });
    }
  };

  const renderShipping = () => {
    const { shipment } = selectedPackage || { shipment: [] }; // Fallback to empty array if no package is selected

    return (
      <div className="flex items-center mt-5 gap-5">
        <span className="font-regular">Shipping:</span>
        <Select
          value={shippingMethod}
          onChange={(value) => setShippingMethod(value)}
          className="max-w-xs"
        >
          {shipment.length > 0 ? (
            shipment.map((ship) => (
              <Select.Option key={ship._id} value={ship._id}>
                <span className="flex items-center gap-5">
                  <span>
                    <span className="font-bold">{ship.shipMentType}</span>
                    <span className="font-regular">{ship.shipmentDescription}</span>
                  </span>
                  <span className="font-regular">
                    ${ship.unitPrice}
                  </span>
                </span>
              </Select.Option>
            ))
          ) : (
            <Select.Option disabled>
              No shipping options available
            </Select.Option>
          )}
        </Select>
      </div>
    );
  };

  const renderProductDetails = () => {
    if (initialValue) {
      const { productName, categoryName, productBrand, packaging, productDescription } = initialValue;

      return (
        <div className="flex flex-col gap-4">
          <div>
            <p>
              <strong>Product Name:</strong> {productName}
            </p>
          </div>
          <div>
            <p>
              <strong>Category:</strong> {categoryName}
            </p>
          </div>
          <div>
            <p>
              <strong>Brand:</strong> {productBrand}
            </p>
          </div>
          <div className="flex items-center gap-3">
            <span className="font-regular">Packaging:</span>
            <div className="flex items-center gap-2">{renderPackageDetail(packaging)}</div>
          </div>
          {renderShipping()}
        </div>
      );
    }
  };

  return (
    <Modal
      width={"800px"}
      open={View_Product_Modal}
      footer={null}
      onCancel={() => {
        dispatch({
          type: "Hide_Modal",
          modalName: "View_Product_Modal",
        });
      }}
    >
      <div className="flex flex-row justify-between mr-4">
        <h4 className="text-bold text-2xl mb-2">
          <span className="capitalize font-bold">{initialValue?.productName}</span>
          <span className="ml-5">
            <span>{renderStatus(initialValue?.status)}</span>
          </span>
        </h4>
      </div>
      <div className="flex flex-row gap-3">
        <Carousel autoplay style={{ width: "400px", backgroundColor: "#000000" }}>
          {renderImageSlider()}
        </Carousel>
        <div>{renderProductDetails()}</div>
      </div>
      <div className="mt-3">
        <span className="font-bold text-xl">Description</span>
        <TextArea rows={10} value={initialValue?.productDescription} />
      </div>
    </Modal>
  );
};

export default Index;
