import React, { useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, message, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

const { TextArea } = Input;

const DeleteModalMessage = ({
  buttonName = "Delete",
  title,
  content,
  okText = "Yes",
  cancelText = "No",
  apiName,
  requestType = "DeleteRecord",
  selectedItem,
  recordName,
  method = "Delete",
  className,
  icon,
}) => {
  const [messageContent, setMessageContent] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ROOT_URL = process.env.REACT_APP_ROOT_URL;
  const dispatch = useDispatch();
  const crudRProps = useSelector((state) => state.CrudR);
  const { deleteRecordSuccess } = crudRProps;

  const handleOk = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    fetch(`${ROOT_URL}/${apiName}`, {
      method: method,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: token,
        APIType: "web",
      }),
      body: JSON.stringify({ ...selectedItem, refundMessage: messageContent }),
    })
      .then((response) => {
        response.json().then((response) => {
          if (response.status === "SUCCESS") {
            dispatch({
              type: `${requestType}_SUCCESS`,
              payload: response,
              recordName: recordName,
            });
            message.success("Record updated successfully");
          } else {
            message.error(response.message);
          }
          setLoading(false);
          setVisible(false);
        });
      })
      .catch((err) => {
        setLoading(false);
        message.error("An error occurred");
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  if (deleteRecordSuccess) {
    dispatch({ type: "RESET_CRUD_STATE" });
  }

  return (
    <>
      <span
        className={`cursor-pointer text-[14px] md:text-[14px] flex items-center gap-2 ${className}`}
        onClick={showModal}
        style={{ cursor: "pointer" }}
      >
        {icon && <span>{icon}</span>} {buttonName}
      </span>
      <Modal
        open={visible}
        title={title}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {cancelText}
          </Button>,
          <Button key="submit" type="primary" className="bg-primary-brown" loading={loading} onClick={handleOk}>
            {okText}
          </Button>,
        ]}
        centered
      >
        <p>{content}</p>
        <TextArea
          rows={4}
          className="mt-2"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Enter your message"
        />
      </Modal>
    </>
  );
};

export default DeleteModalMessage;
