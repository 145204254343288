import React, { useEffect, useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import MerchantForm from "./MerchantForm";
import { Form } from "antd";

const EditUser = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState();
  const { initialValue, Edit_Merchant_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (initialValue) {
      if(initialValue && initialValue.profileImageUrl){
      setProfileImage(initialValue.profileImageUrl)}
      initialValue && initialValue.description
        ? form.setFieldValue("description", initialValue.description)
        : form.getFieldValue("description", "");
      initialValue && initialValue.shortDescription
        ? form.setFieldValue("shortDescription", initialValue.shortDescription)
        : form.getFieldValue("shortDescription", "");
    }
    if (Edit_Merchant_Modal) {
      form.resetFields();
    }
  }, [Edit_Merchant_Modal]);
  return (
    <>
      {Edit_Merchant_Modal && initialValue && (
        <EditFormModal
          form={form}
          apiName="editUser"
          recordName="userList"
          requestType="EditRecord"
          heading="Edit User"
          modalName={"Edit_Merchant_Modal"}
          initialData={initialValue}
          width={"700px"}
          extraValueObj={{ profileImageUrl: profileImage }}
        >
          <MerchantForm
            setProfileImage={setProfileImage}
            profileImage={profileImage}
          />
        </EditFormModal>
      )}
    </>
  );
};

export default EditUser;
