import { Avatar, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
const DirectNotificationForm = ({ form }) => {
  const dispatch = useDispatch();
  const CrudRProps = useSelector((state) => state.CrudR);
  const { postIdsArray, UserForDirectMessage } = CrudRProps;
  const selectPostType = (e) => {
    dispatch(
      DataGetAction(
        "getPostByType",
        { postType: e },
        "FetchRecord",
        "postIdsArray"
      )
    );
  };
  const renderPostIdArrays = () => {
    if (postIdsArray && postIdsArray.length > 0) {
      return postIdsArray.map((item) => {
        return <Select.Option value={item._id}>{item.postId}</Select.Option>;
      });
    }
  };
  const UserForDirectMessageFun = () => {
    if (UserForDirectMessage && UserForDirectMessage.length > 0) {
      return UserForDirectMessage.map((item) => {
        return (
          <Select.Option
            className="custome-item"
            value={item._id}
            style={{ paddingLeft: "60px", position: "relative" }}
          >
            {item.fullName}
            <br />
            {item.email}
            <Avatar
              src={item.profileImageUrl}
              style={{ position: "absolute", top: "15px", left: "10px" }}
            />
          </Select.Option>
        );
      });
    }
  };
  useEffect(() => {
    dispatch(
      DataGetAction(
        "getUserForDirectMessage",
        { query: "all" },
        "FetchRecord",
        "UserForDirectMessage"
      )
    );
  }, []);
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input placeholder="Enter your title" />
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
        <Form.Item
          name="postType"
          label="Type"
          rules={[{ required: true, message: "Select Post type" }]}
        >
          <Select
            placeholder="Select type"
            onChange={(e) => {
              form.setFieldValue("postId", null);
              selectPostType(e);
            }}
          >
            <Select.Option value="sale">Sale</Select.Option>
            <Select.Option value="find">Found</Select.Option>
            <Select.Option value="adopt">Adopt</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <Form.Item
          label="Post Id"
          name="postId"
          rules={[{ required: true, message: "Post Id is required" }]}
        >
          <Select
            showSearch={true}
            optionFilterProp="children"
            placeholder="Select id"
          >
            {renderPostIdArrays()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="usersArray"
          label="Users"
          rules={[{ required: true, message: "Select user is required" }]}
        >
          <Select
            placeholder="Select Users"
            mode="multiple"
            showSearch={true}
            optionFilterProp="children"
            className="userSelect"
          >
            {UserForDirectMessageFun()}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="notificationText"
          label="Notification Text"
          rules={[{ required: true, message: "Notification text is required" }]}
        >
          <Input.TextArea rows={3} placeholder="Type your message here …." />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DirectNotificationForm;
