import { Form, Input } from "antd";
import React from "react";

const AgeForm = () => {
  return (
    <Form.Item
      name="age"
      label="Age"
      rules={[
        {
          required: true,
          message: "Age is required",
        },
      ]}
    >
      <Input placeholder={"Enter your pet age"} />
    </Form.Item>
  );
};

export default AgeForm;
