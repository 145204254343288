import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
const { confirm } = Modal;
const DeleteModal = ({
  buttonName = "Delete",
  title,
  content,
  okText = "Yes",
  cancelText = "No",
  apiName,
  requestType = "DeleteRecord",
  selectedItem,
  recordName,
  method = "Delete",
  className,
  icon,
  showButton = false,
  danger = false,
}) => {
  const ROOT_URL = process.env.REACT_APP_ROOT_URL;
  const dispatch = useDispatch();
  const crudRProps = useSelector((state) => state.CrudR);
  const { deleteRecordSuccess } = crudRProps;
  const showDeleteConfirm = () => {
    confirm({
      title: `${title}`,
      icon: <ExclamationCircleFilled style={{ color: "#F55240" }} />,
      content: content,
      okText: okText,
      cancelText: cancelText,
      centered: true,
      onOk() {
        return new Promise((resolve, reject) => {
          const token = localStorage.getItem("token");
          fetch(`${ROOT_URL}/${apiName}`, {
            method: method,
            headers: new Headers({
              "Content-Type": "application/json",
              Authorization: token,
              APIType: "web",
            }),
            body: JSON.stringify(selectedItem),
          })
            .then((response) => {
              response.json().then((response) => {
                if (response.status === "SUCCESS") {
                  dispatch({
                    type: `${requestType}_SUCCESS`,
                    payload: response,
                    recordName: recordName,
                  });
                } else {
                  message.error(response.message);
                }

                resolve();
              });
            })
            .catch((err) => {
              reject();
            });
        }).catch(() => {
          console.log("===");
        });
      },
      onCancel() {},
    });
  };
  if (deleteRecordSuccess) {
    message.success("Record deleted successfully");
    dispatch({ type: "RESET_CRUD_STATE" });
  }
  if (showButton) {
    return (
      <Button
        className={`cursor-pointer`}
        type="primary"
        shape="circle"
        icon={icon}
        onClick={showDeleteConfirm}
        danger={danger}
      />
    );
  } else {
    return (
      <span
        className={`cursor-pointer  text-[14px] md:text-[14px]  flex items-center gap-2 ${className}`}
        onClick={showDeleteConfirm}
        style={{ cursor: "pointer" }}
      >
        {icon && <span>{icon}</span>} {buttonName}
      </span>
    );
  }
};

export default DeleteModal;
