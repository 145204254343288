import React from "react";
import Table from "../../../components/Table";
import Action from "../../../components/Table/components/Action";
import moment from "moment";
import { renderStatus } from "../../../utils/CommonFunctions";
import Widget from "../../../components/Widget";
import ShowModal from "../../../components/Modal/ShowModal";
import { useLocation, useNavigate } from "react-router-dom";
import ViewPost from "./components/ViewPost";
import EditPost from "./components/EditPost";
import DeleteModal from "../../../components/Modal/DeleteModal";
import Filters from "../Filters";
import ShowLinkClick from "../ShowLinkClick";
const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {record.status === "active" && (
          <DeleteModal
            method="PUT"
            apiName="BlockPost"
            recordName="findPetList"
            requestType="EditRecord"
            title="Block"
            content="Are you sure you want to block this record"
            selectedItem={{ _id: record._id, status: "block" }}
            buttonName="Block"
          />
        )}
        {record.status === "block" && (
          <DeleteModal
            method="PUT"
            apiName="activePost"
            recordName="findPetList"
            requestType="EditRecord"
            title="Active"
            content="Are you sure you want to active this record"
            selectedItem={{ _id: record._id, status: "active" }}
            buttonName={"Active"}
          />
        )}
        <ShowModal
          modalName={"Edit_Post_Find_Modal"}
          record={record}
          name="Edit"
        />
        <ShowModal modalName={"View_Find_Post"} record={record} name="View" />
        <DeleteModal
          apiName="deletePost"
          recordName="findPetList"
          title="Delete"
          content="Are you sure you want to delete this record"
          selectedItem={record}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.postId}</span>,
      key: "id",
    },
    {
      title: "User Info",
      render: (record) => (
        <div className="flex flex-col">
          <span className="capitalize text-[14px] font-bold">
            {record.name}
          </span>
          <span className="text-[12px] text-[#949494]">{record.email}</span>
          <span className="text-[12px] text-[#949494]">
            {record.phoneNumber}
          </span>
        </div>
      ),
      key: "userInfo",
    },
    {
      title: "Post Detail",
      render: (record) => (
        <div className="flex flex-col">
          <span className="capitalize text-[14px] font-bold">
            {record.title}
          </span>
          <span className="text-[12px] text-[#949494]">
            {record.description && record.description.length > 30
              ? record.description.slice(0, 30) + "..."
              : record.description}
          </span>
        </div>
      ),
      key: "postDetail",
    },
    {
      title: "Category",
      render: (record) => (
        <div className="flex flex-col">
          <span className="capitalize text-[14px] font-bold">
            {record.categoryName}
          </span>
          <span className="text-[12px] text-[#949494]">{record.breed}</span>
        </div>
      ),
      key: "Category",
    },
    {
      title: "Clicks",
      render: (record) => (
        <span
          onClick={() => {
            if (record.postClick) {
              navigate("/viewUsers", {
                state: {
                  postType: record.postType,
                  _id: record._id,
                  title: record.title,
                },
              });
            }
          }}
          className="text-primary-brown cursor-pointer"
        >
          {(record && record.postClick) || 0} View
        </span>
      ),
      key: "postClick",
    },

    {
      title: "Age",
      render: (record) => <span>{record.age || "----"}</span>,
      key: "age",
    },
    {
      title: "Color",
      render: (record) => <span>{record.colorName || "----"}</span>,
      key: "colorName",
    },
    {
      title: "Gender",
      render: (record) => <span>{record.genderType || "----"}</span>,
      key: "genderType",
    },
    {
      title: "Links",
      render: (record) => (
        <span>
          {record && record.postLinks && record.postLinks.length > 0 ? (
            <ShowModal
              className="text-primary-brown"
              modalName="View_Links_MODAL"
              name="View"
              record={record.postLinks}
            />
          ) : (
            "---"
          )}
        </span>
      ),
      key: "Links",
    },
    {
      title: "Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "date",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) =>
        record.status !== "deleted" && <Action>{renderAction(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className="p-5">
        <Filters type="find" recordName="findPetList" userId={location.state?.userId} />

        <Table
          columns={columns}
          apiName="getPost"
          recordName="findPetList"
          customQuery={{ type: "find", userId: location.state?.userId }}
        />
      </Widget>
      <ViewPost />
      <EditPost />
      <ShowLinkClick />
    </div>
  );
};

export default Index;
