import React, { useEffect } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form, Input } from "antd";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const { initialValue, Change_User_Old_Password_Modal } = useSelector(
    (state) => state.CrudR
  );

  useEffect(() => {
    if (!Change_User_Old_Password_Modal) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Change_User_Old_Password_Modal]);
  return (
    <>
      {Change_User_Old_Password_Modal && initialValue && (
        <EditFormModal
          form={form}
          apiName="changeUserPassword"
          recordName="userList"
          requestType="EditRecord"
          heading="Change Password"
          modalName={"Change_User_Old_Password_Modal"}
          initialData={{ _id: initialValue._id }}
          width={"500px"}
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Old Password is required",
              },
            ]}
          >
            <Input.Password placeholder="Enter Old Password" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input.Password placeholder="Enter Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Confirm password is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password is not match");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter Confirm Password" />
          </Form.Item>
        </EditFormModal>
      )}
    </>
  );
};

export default ChangePassword;
