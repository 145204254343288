import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Filters = ({ type, recordName, userId }) => {
  const dispatch = useDispatch();
  const { AgeColorGenderCategoryList } = useSelector((state) => state.CrudR);
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(
      DataGetAction(
        "getAgeColorGenderCategory",
        { query: "all" },
        "FetchRecord",
        "AgeColorGenderCategoryList"
      )
    );
  }, []);

  const renderOption = (dataKey, keyValue) => {
    if (
      AgeColorGenderCategoryList &&
      AgeColorGenderCategoryList[dataKey] &&
      AgeColorGenderCategoryList[dataKey].length > 0
    ) {
      return AgeColorGenderCategoryList[dataKey].map((item) => {
        return <Select.Option value={item._id}>{item[keyValue]}</Select.Option>;
      });
    }
  };
  const onFinish = (values) => {
    values["type"] = type;
    for (let key in values) {
      if (values[key] === undefined) {
        delete values[key];
      }
    }

    if(userId){
      values["userId"] = userId
    }

    dispatch(
      DataGetAction(
        "getPost",
        values,
        "FetchRecord",
        recordName,
        "TableSpinner"
      )
    );
  };
  return (
    <Form
      onFinish={onFinish}
      className="flex flex-wrap items-center gap-2 justify-end"
    >
      <Form.Item name="name" className="w-[200px]">
        <Input placeholder="ID / Title / Bread" />
      </Form.Item>
      <Form.Item name="categoryId" className="w-[150px]">
        <Select allowClear placeholder="Select category">
          {renderOption("categoryDetails", "name")}
        </Select>
      </Form.Item>
      <Form.Item name="ageId" className="w-[150px]">
        <Select allowClear placeholder="Select age">
          {renderOption("ageDetails", "age")}
        </Select>
      </Form.Item>
      <Form.Item name="colorId" className="w-[150px]">
        <Select allowClear placeholder="Select color">
          {renderOption("colorDetails", "colorName")}
        </Select>
      </Form.Item>
      <Form.Item name="genderId" className="w-[150px]">
        <Select allowClear placeholder="Select gender">
          {renderOption("genderDetails", "genderType")}
        </Select>
      </Form.Item>
      <Form.Item  name="status" className="w-[150px]">
        <Select allowClear placeholder="Select status">
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="block">Block</Select.Option>
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="deleted">Deleted</Select.Option>
        </Select>
      </Form.Item>
      {userId &&  <Form.Item> <Button
          onClick={() => {
            navigate('/posts', { state: { userId: null } })
            window.location.reload()
          }}
          className=" bg-primary-brown"
          type="primary"
        >
          Show All
        </Button></Form.Item>}
      <Form.Item>
        <Button htmlType="submit" className="bg-primary-brown" type="primary">
          <IoSearchSharp />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Filters;
