import React, { useEffect } from "react";
import DedailModal from "../../../../components/Modal/DedailModal";
import { Col, Form, Image, Input, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
const { Option } = Select;
const ViewPost = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    initialValue,
    View_Sale_Post,
    categoryList,
    colorList,
    ageList,
    genderList,
  } = useSelector((state) => state.CrudR);
  const renterImage = () => {
    if (
      initialValue &&
      initialValue.postImages &&
      initialValue.postImages.length > 0
    ) {
      return initialValue.postImages.map((items) => (
        <div className="mt-1">
          <Image
            style={{ borderRadius: "10px" }}
            src={items}
            alt=""
            width="112px"
            height="100px"
          />
        </div>
      ));
    } else {
      return (
        <h3 style={{ color: "#7D7D7D", margin: "0px auto" }}>
          Images Not Found
        </h3>
      );
    }
  };
  const callApies = () => {
    dispatch(
      DataGetAction(
        "getCategory",
        { query: "all" },
        "FetchRecord",
        "categoryList"
      )
    );
    dispatch(
      DataGetAction("getColor", { query: "all" }, "FetchRecord", "colorList")
    );
    dispatch(
      DataGetAction("getAge", { query: "all" }, "FetchRecord", "ageList")
    );
    dispatch(
      DataGetAction("getGender", { query: "all" }, "FetchRecord", "genderList")
    );
  };
  useEffect(() => {
    if (View_Sale_Post && initialValue) {
      form.setFieldsValue(initialValue);
      callApies()
    }
  }, [View_Sale_Post, initialValue]);
  return (
    <DedailModal heading="View Sale Post" modalName="View_Sale_Post">
      {initialValue && View_Sale_Post && (
        <Form initialValues={initialValue} form={form} layout="vertical">
          <Form.Item name="title" label="Title">
            <Input disabled={true} className="capitalize" />
          </Form.Item>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item name="postType" label="Post Type">
                <Select className="capitalize" disabled={true}>
                  <Option value="find">Find Pet</Option>
                  <Option value="adopt">Adopt Pet</Option>
                  <Option value="sale">Sale Pet</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="categoryId" label="Category">
                <Select className="capitalize" disabled={true}>
                  {categoryList &&
                    categoryList.map((item) => (
                      <Option value={item._id}>{item.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item name="ageId" label="Age">
                <Select className="capitalize" disabled={true}>
                  {ageList &&
                    ageList.map((item) => (
                      <Option value={item._id}>{item.age}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="breed" label="Breed">
                <Input disabled={true} className="capitalize" />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="md:pe-3">
              <Form.Item name="colorId" label="Color">
                <Select className="capitalize" disabled={true}>
                  {colorList &&
                    colorList.map((item) => (
                      <Option value={item._id}>{item.colorName}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item name="genderId" label="Gender">
                <Select className="capitalize" disabled={true}>
                  {genderList &&
                    genderList.map((item) => (
                      <Option value={item._id}>{item.genderType}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="price" label="Price">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="license" label="License">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item label="Attachment">
            <div className="flex items-center justify-center h-[124px] bg-[#F7F8FA] rounded-xl overflow-y-hidden overflow-x-auto gap-3">
              {renterImage()}
            </div>
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} disabled={true} />
          </Form.Item>
          <Form.List name="postLinks">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Row>
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={24}
                        xs={24}
                        className="md:pe-3"
                      >
                        <Form.Item {...restField} name={[name, "name"]}>
                          <Input disabled={true} className="w-full" />
                        </Form.Item>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Form.Item {...restField} name={[name, "link"]}>
                          <Input disabled={true} className="w-full" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      )}
    </DedailModal>
  );
};

export default ViewPost;
