import { Button, Form, Input, Select, Upload } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import { UploadOutlined } from "@ant-design/icons";
const CategoryForm = ({ form }) => {
  const { parentCategory, subCategory, Add_SubCategory_Modal } = useSelector((state) => state.CrudR);


  const renderOption = () => {
    if (parentCategory && parentCategory.length > 0) {
      return parentCategory.map((item) => {
        return <Select.Option value={item._id}>{item.name}</Select.Option>;
      });
    }
  };
  const renderSubCategoryOption = () => {
    if (subCategory && subCategory.length > 0) {
      return subCategory.map((item) => {
        return <Select.Option value={item._id}>{item.name}</Select.Option>;
      });
    }
  };
  return (
    <>
      <Form.Item
        name="parentCategoryId"
        label="Category"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
      >
        <Select placeholder="Select Category">{renderOption()}</Select>
      </Form.Item>

      <Form.Item
        name="subCategory"
        label="Sub Category"
        rules={[
          {
            required: true,
            message: "Sub Category is required",
          },
        ]}
      >
        <Select placeholder="Select Sub Category" mode="multiple">
          {renderSubCategoryOption()}
        </Select>
      </Form.Item>
    </>
  );
};

export default CategoryForm;
