import React from "react";
import Table from "../../../components/Table";
import EditColor from "./components/EditColor";
import TableHeader from "./components/TableHeader";
import ShowModal from "../../../components/Modal/ShowModal";
import moment from "moment";
import DeleteModal from "../../../components/Modal/DeleteModal";
import Action from "../../../components/Table/components/Action";
import Widget from "../../../components/Widget";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName="EDIT_COLOR_MODAL" name="Edit" record={record} />
        <DeleteModal
          apiName="deleteColor"
          recordName="colorList"
          title="Delete"
          content="Are you sure you want to delete this record"
          selectedItem={record}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.colorId}</span>,
      key: "id",
      width: "100px",
    },
    {
      title: "Colour",
      render: (record) => (
        <span className="capitalize">{record.colorName}</span>
      ),
      key: "colorName",
      width: "150px",
    },
    {
      title: "Created At",
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className='p-5'>
        <TableHeader />
        <Table apiName="getColor" recordName="colorList" columns={columns} />
      </Widget>
      <EditColor />
    </div>
  );
};
export default Index;
