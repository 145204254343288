import { addRecord, updateRecord, deleteRecord } from "./CommonFn";

const initialState = {
  fetchingSpinner: false,
  fetchingModalSpinner: false,
  addRecordSuccess: false,
  addRecordSpin: false,
  editRecordSpin: false,
  editRecordSuccess: false,
  deleteRecordSuccess: false,
  updateMenuData: false,
  newBooking: 0,
  disputeMessage: null,
  newMessage: null,
  orderFetch:0
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case "AddDisputeRecord_SUCCESS": {
      console.log("===== adding Dispute Record ====",action)
      return {
        ...state,
        disputeMessage: action.payload.Record,
        addRecordSpin: false,
        addRecordSuccess: true,
      };
    }
    case "disputeMessage_SUCCESS": {
      return {
        ...state,
        disputeMessage: action.payload,
      };
    }
    case "reduceTheMenuCount_SUCCESS": {
      let oldData = state.menuCardData;
      let newData = action.payload;
      const keys = Object.keys(newData);
      oldData[keys[0]] = parseInt(oldData[keys[0]]) - 1;
      return {
        ...state,
        menuCardData: { ...oldData },
      };
    }
    case "updateMenuCard_SUCCESS": {
      let oldData = state.menuCardData;
      let newData = action.payload;
      const keys = Object.keys(newData);
      oldData[keys[0]] = parseInt(oldData[keys[0]]) + 1;
      return {
        ...state,
        menuCardData: { ...oldData },
      };
    }

    case "NewBookingCome_SUCCESS": {
      return {
        ...state,
        newBooking: state.newBooking + 1,
      };
    }
    case "OrderFetch_SUCCESS": {
      return {
        ...state,
        orderFetch: state.orderFetch+1,
      };
    }
    case "UpdateMenuData_SUCCESS": {
      return {
        ...state,
        updateMenuData: action.payload,
      };
    }

    case "Hide_Modal": {
      let key = "initialValue";
      if (action.dynamicValueName) {
        key = `initialValue_${action.dynamicValueName}`;
      }
      return {
        ...state,
        [action.modalName]: false,
        [key]: null,
      };
    }
    case "Show_Modal": {
      let key = "initialValue";
      if (action.dynamicValueName) {
        key = `initialValue_${action.dynamicValueName}`;
      }
      return {
        ...state,
        [action.modalName]: true,
        [key]: action.payload,
      };
    }

    case "FetchRecord_SUCCESS": {
      return {
        ...state,
        [action.recordName]: action.payload.Record,
        fetchingSpinner: false,
        fetchingModalSpinner: false,
      };
    }
    case "FetchRecord_ERROR": {
      return {
        ...state,
        fetchingSpinner: false,
      };
    }

    case "fetchingResult_START": {
      return {
        ...state,
        fetchingModalSpinner: true,
      };
    }
    case "TableSpinner_START": {
      return {
        ...state,
        fetchingSpinner: true,
      };
    }
    case "AddRecord_SUCCESS": {
      let Record = addRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        addRecordSpin: false,
        addRecordSuccess: true,
      };
    }
    case "AddRecord_ERROR": {
      return {
        ...state,
        addRecordSpin: false,
      };
    }
    case "AddFormModalSpin_START": {
      return {
        ...state,
        addRecordSpin: true,
      };
    }
    case "EditRecord_SUCCESS": {
      let Record = updateRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        editRecordSpin: false,
        editRecordSuccess: true,
      };
    }
    case "EditRecord_ERROR": {
      return {
        ...state,
        editRecordSpin: false,
      };
    }
    case "editFormModalSpin_START": {
      return {
        ...state,
        editRecordSpin: true,
      };
    }
    case "DeleteRecord_SUCCESS": {
      let Record = deleteRecord(state[action.recordName], action.payload.Record);
      return {
        ...state,
        [action.recordName]: [...Record],
        deleteRecordSuccess: true,
      };
    }
    case "RESET_CRUD_STATE": {
      return {
        ...state,
        addRecordSuccess: false,
        editRecordSuccess: false,
        deleteRecordSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};
