import React from "react";
import ListedList from "../../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../../components/Widget";
import moment from "moment";
import { renderStatus } from "../../../utils/CommonFunctions";
import { Avatar } from "antd";
import placeholder from "../../../assets/images/placeholder.webp";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ShowModal from "../../../components/Modal/ShowModal";
import Action from "../../../components/Table/components/Action";
import { useDispatch, useSelector } from "react-redux";
import ViewProductModal from "./components/ViewProductModal";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

const Index = ({ selectedTab }) => {
  const { productList } = useSelector((state) => state.CrudR);
  const dispatch = useDispatch();
  const location = useLocation();
  console.log("==== productList ====", productList);
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {record.status === "active" && (
          <DeleteModal
            showButton={true}
            icon={<CloseOutlined />}
            method="PUT"
            apiName="editProduct"
            recordName="productList"
            requestType="DeleteRecord"
            title="De-Listed"
            content="Are you sure you want to de-list this record"
            selectedItem={{ _id: record._id, status: "block" }}
            buttonName="De-List"
            danger={true}
          />
        )}
      </div>
    );
  };

  const handleViewProduct = (record) => {
    dispatch({
      type: "Show_Modal",
      modalName: "View_Product_Modal",
      payload: record, // Pass the product record to the modal
    });
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.productId}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "Product Name",
      render: (record) => (
        <span className="flex items-center gap-2">
          <Avatar src={record && record.uploadImage[0]} />
          {record.productName}
        </span>
      ),
      key: "productName",
    },
    {
      title: "Merchant's Name",
      render: (record) => {
        return (
          <span className="capitalize flex items-center gap-2">
            <Avatar src={record?.profileImageUrl || placeholder} />
            {record?.fullName || "---"}
          </span>
        );
      },
      key: "merchantName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
    },
    {
      title: "Category",
      render: (record) => <span>{record.categoryName || "---"}</span>,
      key: "category",
    },
    {
      title: "Description",
      render: (record) => (
        <span
          className="text-primary-brown cursor-pointer"
          onClick={() => handleViewProduct(record)}
        >
          {record && record.packaging && record.packaging.length > 0
            ? "View"
            : "---"}
        </span>
      ),
      key: "unitPrice",
    },
    {
      title: "Date",
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "date",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => renderAction(record),
      key: "action",
      width: "50px",
    },
  ];

  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className="p-5">
        <TableHeader userId={location.state?.userId} />
        <ListedList
          columns={columns}
          apiName="getProduct"
          recordName="productList"
          customQuery={{ status: "active", userId: location.state?.userId }}
          customCondition={selectedTab}
        />
      </Widget>
      <ViewProductModal />
    </div>
  );
};

export default Index;
