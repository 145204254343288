"use client";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Upload, Rate, Modal, Avatar } from "antd";
import { UserOutlined, SendOutlined, FileImageOutlined } from "@ant-design/icons";

import moment from "moment";
import { DataRequestAction } from "../../../../redux/actions/CommonHttp";

const DisputeModal = () => {
  const chatContainerRef = useRef(null);
  const { authUser } = useSelector((state) => state.AuthR);
  const {
    DisputeModal,
    addRecordSuccess,
    initialValue_DisputeModal,
    messages,
    addRecordSpin,
    newMessage,
    disputeMessage,
  } = useSelector((state) => state.CrudR);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [disputeArray, setDisputeArray] = useState([]);
  useEffect(() => {
    if (disputeMessage) {
      let filterObj = disputeArray.filter((x) => x.message === disputeMessage.message);
      if (filterObj.length === 0) {
        setDisputeArray((prevValue) => [...prevValue, disputeMessage]);
      }
    }
  }, [disputeMessage]);

  useEffect(() => {
    if (initialValue_DisputeModal && initialValue_DisputeModal.dispute) {
      setDisputeArray(initialValue_DisputeModal.dispute);
    }
    return () => {
      setDisputeArray([]);
    };
  }, [initialValue_DisputeModal]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when conversationList changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [disputeArray]);

  const handleCancel = () => {
    dispatch({
      type: "Hide_Modal",
      modalName: "DisputeModal",
      dynamicValueName: "DisputeModal",
    });
  };

  if (addRecordSuccess) {
    dispatch({
      type: "RESET_CRUD_STATE",
    });
  }
  const handleSend = () => {
    if (message !== "") {
      dispatch(
        DataRequestAction(
          "POST",
          "addDispute",
          {
            message: message,
            productId: initialValue_DisputeModal.productId,
            _id: initialValue_DisputeModal._id,
            createdBy: "Admin",
          },
          "AddDisputeRecord",
          "dispute",
          "AddingDispute"
        )
      );
      setMessage("");
    }
  };

  const renderProductDetail = () => {
    if (initialValue_DisputeModal) {
      const { productName, orderId, totalPrice, returnReason, createdAt, productImages, merchantInfo, userInfo } =
        initialValue_DisputeModal;
      return (
        <div className="flex flex-row justify-between px-4 py-2 border-2 border-[#e5e7eb] mb-4 rounded">
          <div className="flex flex-row">
            <img src={productImages?.[0]} alt="Product" className="image-container" />
            <div className="flex flex-col">
              <p className="w-[250px]">Merchant: {merchantInfo?.fullName}</p>
              <p className="w-[250px]">{productName}</p>
              <p className="font-bold text-[14px]">Price: ${totalPrice}</p>
            </div>
          </div>
          <div>
            <div className="font-semibold flex items-start w-full">
              <span className="font-bold text-[16px] text-[#000000]">Order: </span>
              <span className=" text-[14px]"> #{orderId}</span>
            </div>
            <div className="text-gray-500 text-xs mb-2">
              <span className="font-bold text-[16px] text-[#000000]">Disputed at:</span>
              <span className=" text-[14px]"> {moment(createdAt).format(process.env.REACT_APP_DATE_FORMATE)}</span>
            </div>
            <div className="text-gray-500 text-xs mb-2">
              <span className="font-bold text-[16px] text-[#000000]">Reason:</span>
              <span className=" text-[14px]"> {returnReason}</span>
            </div>
            <div className="text-gray-500 text-xs mb-2">
              <span className="font-bold text-[16px] text-[#000000]">Customer:</span>
              <span className=" text-[14px]"> {userInfo?.fullName}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderChatMessages = () => {
    if (disputeArray && disputeArray.length > 0) {
      return disputeArray.map((message, index) => {
        let sender = message.userId === authUser._id ? true : false;
        let messageBy =
          initialValue_DisputeModal?.merchantInfo?._id === message.userId
            ? initialValue_DisputeModal?.merchantInfo
            : initialValue_DisputeModal?.userInfo;

        return (
          <div key={index} className={`relative mb-4  ${sender ? "flex-row-reverse text-right" : ""} flex`}>
            <div
              className={`p-4 rounded ${sender ? "bg-[#def7ec] ml-auto" : "bg-[#e5e7eb]"} mb-4`}
              style={{ width: "calc(50% - 30px)" }}
            >
              <div className="text-gray-800 font-bold text-[14px]">
                {message?.createdBy !== "Admin" ? messageBy?.fullName : ""}
              </div>
              <div className="text-gray-800">{message?.message}</div>
              <div className="text-gray-400 text-xs">
                {moment(message?.createdAt).format(process.env.REACT_APP_DATE_FORMATE)}
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <Modal
      title=""
      width={"800px"}
      open={DisputeModal}
      footer={null}
      onOk={() => {
        form.submit();
      }}
      onCancel={handleCancel}
    >
      <p className="flex justify-center font-bold text-xl">Dispute Dialog Box</p>
      <div className="p-4">
        {renderProductDetail()}
        <div className="bg-gray-100 p-4 rounded mb-4 flex items-center flex-col">
          <div className="chat-container overflow-y-auto h-96 p-2 w-full" ref={chatContainerRef}>
            {renderChatMessages()}
          </div>
        </div>
        {initialValue_DisputeModal && initialValue_DisputeModal.refundStatus !== "refunded" && (
          <div className="mt-4 flex items-center gap-2">
            <Input.TextArea
              rows={1}
              placeholder="Type a message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="flex-grow rounded "
            />
            <Button loading={addRecordSpin} type="primary" icon={<SendOutlined />} onClick={handleSend} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DisputeModal;
