import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

const BannerUploader = ({ imageUrl, setImageUrl }) => {
  const [loading, setLoading] = useState(false);
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
        setLoading(false);
        setImageUrl(info.file.response.url.location);
    }
  };
  const uploadButton = (
    <div >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <div>
      <Upload
        name="file"
        listType="picture-card"
        className="banner-uploader"
        showUploadList={false}
        action={`${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <div className="w-[100%] h-[200px] overflow-hidden">
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "100%",
              height:'100%'
            }}
          />
          </div>
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default BannerUploader;
