import { Button, Form, Input, Select, Upload } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../../redux/actions/CommonHttp";
import { UploadOutlined } from "@ant-design/icons";
const ServicesSubCategoryForm = ({form}) => {
  const { parentServiceCategory } = useSelector((state) => state.CrudR);

  const token = localStorage.getItem("token");

  const handleChange = (info) => {
    console.log("===== info =====", info);
    if (info.file.status === "done") {
      form.setFieldValue("categoryImage", info.file.response.url.location);
    }
  };

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    onChange: handleChange,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      DataGetAction(
        "categories",
        { query: "all", type: "service" },
        "FetchRecord",
        "parentServiceCategory"
      )
    );
  }, []);
  const renderOption = () => {
    if (parentServiceCategory && parentServiceCategory.length > 0) {
      return parentServiceCategory.map((item) => {
        return <Select.Option value={item._id}>{item.name}</Select.Option>;
      });
    }
  };
  return (
    <>
      <Form.Item
        name="parentCategoryId"
        label="Category"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
      >
        <Select placeholder="Select Category">{renderOption()}</Select>
      </Form.Item>
      <Form.Item
        name="name"
        label="Sub Category"
        rules={[
          {
            required: true,
            message: "Sub Category is required",
          },
        ]}
      >
        <Input placeholder="Enter sub category" showCount maxLength={20}/>
      </Form.Item>
      <Form.Item name="categoryImage" label="Category Image" rules={[{ required: true }]}>
        <Upload listType="picture" maxCount={1} {...ImageProps}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </>
  );
};

export default ServicesSubCategoryForm;
