import { Button, Form, Input, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";

const socialMediaPlatforms = [
  { name: "Facebook", label: "Facebook" },
  { name: "Twitter", label: "Twitter" },
  { name: "Instagram", label: "Instagram" },
  { name: "WhatsApp", label: "WhatsApp" },
  { name: "TikTok", label: "TikTok" },
  { name: "YouTube", label: "YouTube" },
];

const SocialMediaForm = ({ visible, onClose, initialValue }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { fetchingSpinner } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue(initialValue);
    } else {
      const initialValues = { socialMedia: socialMediaPlatforms.map(platform => ({ name: platform.name, url: '' })) };
      form.setFieldsValue(initialValues);
    }
  }, [initialValue]);

  const onFinish = (values) => {
    values["pageType"] = "socialMedia";
    dispatch(DataRequestAction("PUT", "editPageData", values, "FetchRecord", "socialMediaList", "TableSpinner", null));
    onClose(); // Close the modal after submission
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal visible={visible} title="Social Media Links" onCancel={handleCancel} footer={null} maskClosable={false}>
      <Form layout="vertical" initialValues={initialValue} onFinish={onFinish} form={form}>
        {socialMediaPlatforms.map((platform, index) => (
          <div key={index}>
            <Form.Item
              label={platform.label}
              name={['socialMedia', index, 'url']}
              rules={[{ required: true, message: `${platform.label} URL is required` }]}
            >
              <Input placeholder={`Enter ${platform.label} URL`} />
            </Form.Item>
            <Form.Item name={['socialMedia', index, 'name']} initialValue={platform.name} hidden>
              <Input />
            </Form.Item>
          </div>
        ))}
        <div className="flex items-center justify-end gap-3">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary-brown"
            loading={fetchingSpinner}
            disabled={fetchingSpinner}
          >
            Update
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SocialMediaForm;
