import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import ServicesSubCategoryForm from "./ServicesSubCategoryForm";

const AddServicesSubCategory = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName="Add_Services_SubCategory_Modal"
      heading="Add Sub Category"
      apiName="addSubCategories"
      recordName="servicesSubCategoryList"
      width={"500px"}
      addButtonName="Add Sub Category"
      extraValueObj={{type:"service"}}
    >
      <ServicesSubCategoryForm form={form}/>
    </AddFormModal>
  );
};

export default AddServicesSubCategory;
