import { Modal, Carousel, Empty } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, renderStatus } from "../../../utils/CommonFunctions";
import TextArea from "antd/es/input/TextArea";

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const { ViewServiceModal, initialValue } = useSelector((state) => state.CrudR);
  const contentStyle = {
    margin: 0,
    with: "400px",
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const renderImageSlider = () => {
    if (initialValue) {
      const renderImageSlider = () => {
        if (initialValue?.serviceImage && initialValue?.serviceImage.length > 0) {
          const { serviceImage } = initialValue;
          return serviceImage.map((Item) => {
            const { url } = Item;
            return (
              <div style={contentStyle}>
                <img
                  src={url}
                  style={{
                    width: "400px", // If you want to maintain this width, you can keep it
                    height: "400px", // Set the desired height
                    border: "2px solid #722f38", // Add the border here
                    objectFit: "cover", // To ensure the image fits well within the given dimensions
                  }}
                />
              </div>
            );
          });
        }
      };

      if (initialValue?.serviceImage && initialValue?.serviceImage.length > 0) {
        return (
          <Carousel
            autoplay={true}
            style={{
              width: "400px",
              backgroundColor: "#000000",
            }}
            onChange={(props) => console.log("==== onchange  ====", props)}
          >
            {renderImageSlider()}
          </Carousel>
        );
      } else {
        return (
          <div>
            <Empty description="No Image Uploaded" />
          </div>
        );
      }
    }
  };

  const renderServiceDate = () => {
    if (initialValue) {
      const { serviceStartDate, serviceEndDate, hours = "00", min = "00", days = 0, slots, serviceRepeat } = initialValue;
      console.log("===== serviceStartDate", serviceStartDate);
      return (
        <div className="flex flex-col gap-2">
          <div>
            <p>Price: ${initialValue?.price}</p>
          </div>
          <div>
            <p>
              Service Date: {moment(serviceStartDate).format(process.env.REACT_APP_DATE_FORMATE)} -{" "}
              {moment(serviceEndDate).format(process.env.REACT_APP_DATE_FORMATE)}
            </p>
          </div>
          <div>
            <p>
              Duration: {days}d {hours}h {min}m
            </p>
          </div>
          <div>
            <p>Slots: {slots}</p>
          </div>
          <div>
            <p>Repeated: {capitalize(serviceRepeat)}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      width={"800px"}
      open={ViewServiceModal}
      footer={null}
      onCancel={() => {
        dispatch({
          type: "Hide_Modal",
          modalName: "ViewServiceModal",
        });
      }}
    >
      <div className="flex flex-row justify-between mr-4">
        <h4 className="text-bold text-2xl mb-2 ">
          <span>{initialValue?.serviceName}</span> <span className="ml-5">{renderStatus(initialValue?.status)}</span>
        </h4>
      </div>
      <div className="flex flex-row gap-3">
        {renderImageSlider()}
        <div>{renderServiceDate()}</div>
      </div>
      <div className="mt-3">
        <span className="font-bold text-xl">Description</span>
       <TextArea rows={10} value={initialValue?.description}/>
      </div>
    </Modal>
  );
};

export default ServiceDetail;
