import React from "react";
import OrderList from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import { capitalize, renderStatus } from "../../utils/CommonFunctions";
import { Avatar, Button } from "antd";
import placeholder from "../../assets/images/placeholder.webp";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Action from "../../components/Table/components/Action";
import DeleteModal from "../../components/Modal/DeleteModal";
//import ServiceDetail from "./components/ServiceDetail";
import { useLocation } from "react-router-dom";
import ProductDetail from "./components/ProductDetail";
const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        {record.status === "active" && (
          <DeleteModal
            method="PUT"
            apiName="changeService"
            recordName="servicesList"
            requestType="EditRecord"
            title="Block"
            content="Are you sure you want to block this record"
            selectedItem={{ _id: record._id, status: "block" }}
            buttonName="Block"
          />
        )}
        {record.status === "block" && (
          <DeleteModal
            method="PUT"
            apiName="changeService"
            recordName="servicesList"
            requestType="EditRecord"
            title="Active"
            content="Are you sure you want to active this record"
            selectedItem={{ _id: record._id, status: "active" }}
            buttonName="Active"
          />
        )}
        <span
          className="cursor-pointer"
          onClick={() => {
            dispatch({
              type: "Show_Modal",
              modalName: "ViewServiceModal",
              payload: record,
            });
          }}
        >
          View
        </span>
      </div>
    );
  };

  const showProductDetail = (data, orderId) => {
    data[0]['orderId'] = orderId;
    dispatch({
      type: "Show_Modal",
      modalName: "ProductDetail",
      payload: data,
    });
  };

  const renderTotalPrice = (data) => {
    if (data && data.products) {
      return data.products.reduce((total, item) => total + parseFloat(item.totalPrice), 0);
    }
  };


  const columns = [
    { title: "Order Id", key: "orderId", render: (record) => <span>{record?.orderId}</span> },
    {
      title: "Customer",
      key: "name",
      render: (record) => (
        <div className="flex items-center gap-2">
          <Avatar src={record?.customerInfo?.[0]?.profileImageUrl} />
          <span>{record?.customerInfo?.[0]?.fullName}</span>
        </div>
      ),
    },
    {
      title: "Product's Detail",
      key: "products",
      render: (record) => (
        <Button onClick={() => showProductDetail(record?.products, record._id)}>
          {record.products?.[0]?.productName} {record?.products?.length > 1 && "& More"}
        </Button>
      ),
    },
    {
      title: "Total Price",
      key: "price",
      render: (record) => (
        <span>
          {process.env.REACT_APP_CURRENCY}
          {renderTotalPrice(record)}
        </span>
      ),
    },
    {
      title: "Date",
      key: "date",
      render: (record) => moment(record.createdAt).format("DD MMM YYYY"),
    },
    {
        title: "Status",
        render: (record) => <span>{renderStatus(record.status)}</span>,
        key: "status",
      },
  ];

  let data = [
    {
      userId: 1,
      username: "ammad",
      fullName: "Ammad Arshad",
      email: "ammadarshad@gmail.com",
      phoneNumber: "923173035924",
      orders: 1,
      booking: 1,
      price: 12,
      createdAt: new Date(),
      status: "active",
    },
  ];
  return (
    <Widget className="p-5">
      <TableHeader userId={location.state?.userId} />
      <OrderList
        columns={columns}
        tempData={data}
        apiName="getOrderForAdmin"
        recordName="servicesList"
        customQuery={{ userId: location.state?.userId, status: "pending" }}
      />
       <ProductDetail/>
    </Widget>
  );
};

export default Index;

