import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import { Button, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import PrivacyPolicyModal from "./PrivacyPolicy";
const Index = () => {
  const dispatch = useDispatch();
  const { PrivacyPolicyList } = useSelector((state) => state.CrudR);

  useEffect(() => {
    dispatch(
      DataGetAction(
        "getPageData",
        { pageType: "privacyPolicy" },
        "FetchRecord",
        "PrivacyPolicyList"
      )
    );
  }, []);

  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className="p-5">
        <div className="flex justify-end">
          <Button
            className="bg-primary-brown mb-3"
            type="primary"
            onClick={() =>
              dispatch({
                type: "Show_Modal",
                modalName: "Open_Privacy_policy_Page_Modal",
                payload: PrivacyPolicyList,
              })
            }
          >
            Edit privacy policy
          </Button>
        </div>
        {PrivacyPolicyList && (
          <>
            <div>
              <div className="relative w-full h-full">
                <img
                  src={PrivacyPolicyList && PrivacyPolicyList.bannerImageUrl}
                  className="w-full h-[400px]"
                />
                <div
                  className="absolute top-[50%] left-[40%] p-5 rounded-lg"
                  style={{
                    boxShadow: "0px 9px 6px #0000005C",
                    background: "#ECECEC80",
                  }}
                >
                  <h3 className="text-[26px] font-bold text-[#FFFFFF]">
                    {PrivacyPolicyList && PrivacyPolicyList.title}
                  </h3>
                </div>
              </div>
              <div className="my-4">
                <h3 className="my-3 text-[16px]">
                  {PrivacyPolicyList && PrivacyPolicyList.heroHeading}
                </h3>
                <p>{PrivacyPolicyList && PrivacyPolicyList.description}</p>
              </div>
            </div>
          </>
        )}
        {!PrivacyPolicyList && (
          <div className="my-10 flex items-center justify-center">
            <Empty />
          </div>
        )}
      </Widget>
      <PrivacyPolicyModal />
    </div>
  );
};
export default Index;
