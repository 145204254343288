import React, { useState } from "react";
import Table from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import Action from "../../components/Table/components/Action";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import EditNews from "./components/EditNews";
import ViewNews from "./components/ViewNews";
import { Modal } from "antd";

const ShortDescriptionCell = ({ description }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const truncatedDescription = description?.length > 50 ? `${description.substring(0, 50)}...` : description;

  return (
    <>
      <span className="capitalize hover:underline" onClick={showModal} style={{ cursor: "pointer", color: "#722F38" }}>
        {truncatedDescription || "---"}
      </span>
      <Modal
        className="mt-20 text-justify overflow-y-auto"
        title="Description"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="modal-container">
          <p className="modal-content">{description || "---"}</p>
        </div>
      </Modal>
    </>
  );
};

const Index = () => {
  const renderAction = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName={"Edit_News_Modal"} record={record} name="Edit" />
        <DeleteModal
          apiName="deleteNews"
          recordName="newsList"
          requestType="DeleteRecord"
          selectedItem={record}
          title="Delete"
          content="Are you sure you want to delete this record?"
        />
      </div>
    );
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.newsId}</span>,
      key: "Id",
    },
    {
      title: "Title",
      render: (record) => <span className="capitalize">{record.title || "---"}</span>,
      key: "title",
    },
    {
      title: "Short Description",
      render: (record) => <ShortDescriptionCell description={record.shortDescription} />,
      key: "shortDescription",
      width: "30%",
    },
    {
      title: "Attach Files",
      render: (record) => <span>{record && record.fileUrl && record.fileUrl.length > 0 ? record.fileUrl.length : 0}</span>,
      key: "fileUrl",
    },
    {
      title: "Detail",
      render: (record) => <ShowModal modalName="View_News_Modal" record={record} name="View" className="text-primary-brown" />,
      key: "detail",
    },
    {
      title: "Created At",
      render: (record) => <span>{moment(record.createdAt).format("MMM-DD-YYYY") || "---"}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderAction(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <Table columns={columns} apiName="getNews" recordName="newsList" />
      </Widget>
      <EditNews />
      <ViewNews />
    </>
  );
};

export default Index;
