import React, { useEffect, useState } from "react";
import EditFormModal from "../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import BannerForm from "./BannerForm";
import { Form } from "antd";

const EditBanner = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState();
  const { initialValue, Edit_Banner_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (initialValue) {
      if (initialValue && initialValue.profileImageUrl) {
        setProfileImage(initialValue.profileImageUrl);
      }
    }
    if (Edit_Banner_Modal) {
      form.resetFields();
    }
  }, [Edit_Banner_Modal]);
  return (
    <>
      {Edit_Banner_Modal && initialValue && (
        <EditFormModal
          form={form}
          apiName="updateBanner"
          recordName="bannerList"
          requestType="EditRecord"
          heading="Edit Banner"
          modalName={"Edit_Banner_Modal"}
          initialData={initialValue}
          width={"600px"}
          extraValueObj={{ profileImageUrl: profileImage }}
        >
          <BannerForm
            formType={"edit"}
            setProfileImage={setProfileImage}
            profileImage={profileImage}
            type={initialValue && initialValue.type}
          />
        </EditFormModal>
      )}
    </>
  );
};

export default EditBanner;
