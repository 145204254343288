import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import NavCard from "./components/NavCard";
import { ImUser } from "react-icons/im";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";
import { MdLibraryAdd, MdFastfood } from "react-icons/md";
import UserInfo from "./components/UserInfo";
import Navbar from "./components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import UNAUTHORIZEDModal from "../Modal/UNAUTHORIZEDModal";
import { BsFillCartCheckFill, BsCardList } from "react-icons/bs";
import { TiShoppingCart } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const Index = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { menuCardData, totalServices, totalOrders, updateMenuData } = useSelector((state) => state.CrudR);
  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100;
    }
  };
  const renderLogo = () => {
    return (
      <div onClick={() => {navigate("/dashboard")}} className="w-[80px] h-[70px] bg-primary-yellow rounded-3xl flex items-center pt-1 drop-shadowprimary-yellow cursor-pointer">
        <img src={Logo} alt="Logo" />
      </div>
    );
  };
  const handleScroll = () => {
    const container = containerRef.current;
    setShowLeftButton(container.scrollLeft > 0);
    setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth);
  };

  useEffect(() => {
    dispatch(DataGetAction("getMenuData", "", "FetchRecord", "menuCardData"));
  }, []);
  useEffect(() => {
    if (updateMenuData) {
      dispatch(DataGetAction("getMenuData", "", "FetchRecord", "menuCardData"));
      dispatch({
        type: "UpdateMenuData_SUCCESS",
        payload: false,
      });
    }
  }, [updateMenuData]);

  const renderCards = () => {
    return (
      <div
        onScroll={handleScroll}
        style={{
          overflowX: "auto",
          scrollBehavior: "smooth",
        }}
        className="flex items-center gap-2 w-full scrollbar-hide"
        ref={containerRef}
      >
        <NavCard
          title="Total Users:"
          count={menuCardData && menuCardData.totalActiveUser}
          iconColorbg="#FBD6E1"
          color="#FF2366"
          routeName="/user"
          icon={<ImUser size={20} />}
        />
        <NavCard
          title="Total Merchants:"
          count={menuCardData && menuCardData.totalMerchant}
          iconColorbg="#FBEAD3"
          color="#FF4F00"
          routeName="/merchant"
          icon={<ImUser size={20} />}
        />
        <NavCard
          title="Pet Posting:"
          count={menuCardData && menuCardData.totalPost}
          iconColorbg="#D5E4FB"
          color="#1F75FE"
          routeName="/posts"
          icon={<MdLibraryAdd size={20} />}
        />
        {/* <NavCard
          title="Product Posting:"
          count={menuCardData && menuCardData.totalProduct}
          iconColorbg="#EEECDB"
          color="#B5A642"
          routeName={"/petProductPosting"}
          icon={<MdFastfood size={20} />}
        />
        <NavCard
          title="Order:"
          count={menuCardData && menuCardData.totalOrder}
          iconColorbg="#F2DDD0"
          color="#C94F00"
          routeName="/orders"
          icon={<TiShoppingCart size={20} />}
        /> */}
        {/* <NavCard
          title="Service Posting:"
          count={menuCardData && menuCardData.totalService}
          iconColorbg="#E4D3DF"
          color="#79155B"
          icon={<BsFillCartCheckFill size={20} />}
          routeName="/services"
        />
        <NavCard
          title="Booking:"
          count={menuCardData?.totalBooking || 0}
          iconColorbg="#E4D3DF"
          color="#ffc107"
          icon={<BsCardList size={20} />}
          routeName="/booking"
        /> */}
      </div>
    );
  };
  useEffect(() => {
    const container = containerRef.current;
    setShowLeftButton(container.scrollLeft > 0);
    setShowRightButton(container.scrollLeft < container.scrollWidth - container.clientWidth);
  }, []);

  return (
    <div>
      <div className="sticky top-0 z-[1]">
        <div className="p-4 bg-[#fff] drop-shadow-xl">
          <div className="flex items-center gap-2">
            {renderLogo()}
            <span className="h-[70px] border border-[#B7969A]"></span>
            {showLeftButton && (
              <div
                onClick={() => scrollLeft()}
                className="w-[30px] h-[26px] rounded-full bg-[#fff] drop-shadow-xl border border-[#BFBFBF] flex items-center justify-center mr-[-20px]"
              >
                <BiSolidChevronLeft />
              </div>
            )}
            {renderCards()}
            {showRightButton && (
              <div
                onClick={() => scrollRight()}
                className="w-[30px] h-[26px] rounded-full bg-[#fff] drop-shadow-xl min-[1500px]:hidden flex border border-[#BFBFBF]  items-center justify-center ml-[-20px]"
              >
                <BiSolidChevronRight />
              </div>
            )}
            <UserInfo />
          </div>
        </div>
        <Navbar />
      </div>
      <div className="p-4">{children}</div>
      <UNAUTHORIZEDModal />
    </div>
  );
};

export default Index;
