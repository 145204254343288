import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import ColorForm from "./ColorForm";

const EditColor = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_COLOR_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_COLOR_MODAL) {
      form.resetFields();
    }
  }, [EDIT_COLOR_MODAL]);
  return (
    <>
      {EDIT_COLOR_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="updateColor"
          recordName="colorList"
          requestType="EditRecord"
          heading="Edit Color"
          modalName={"EDIT_COLOR_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <ColorForm />
        </EditFormModal>
      )}
    </>
  );
};

export default EditColor;
