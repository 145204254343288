import React from "react";

import AddAge from "./AddAge";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-end flex-wrap mb-3">
      <AddAge />
    </div>
  );
};

export default TableHeader;
