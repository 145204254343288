import React, { useEffect } from "react";
import Widget from "../../../components/Widget";
import { Button, Collapse, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../FormModal";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import FAQModal from "./FAQModal";
const { Panel } = Collapse;
const Index = ({ tabsType }) => {
  const dispatch = useDispatch();
  const { FaqsList } = useSelector((state) => state.CrudR);
  const renderFaqQuestion = () => {
    const renderPanels = () => {
      return FaqsList.questionAndAnswer.map((item, index) => {
        return (
          <Panel header={item.question} key={index}>
            {item.answer}
          </Panel>
        );
      });
    };
    if (
      FaqsList &&
      FaqsList.questionAndAnswer &&
      FaqsList.questionAndAnswer.length > 0
    ) {
      return <Collapse>{renderPanels()}</Collapse>;
    } else {
      return (
        <div className="h-300px">
          <Empty />
        </div>
      );
    }
  };

  useEffect(() => {
    dispatch(
      DataGetAction(
        "getPageData",
        { pageType: "faq" },
        "FetchRecord",
        "FaqsList"
      )
    );
  }, []);

  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className="p-5">
        <div className="flex justify-end">
          <Button
            className="bg-primary-brown mb-3"
            type="primary"
            onClick={() =>
              dispatch({
                type: "Show_Modal",
                modalName: "Open_FAQ_Page_Modal",
                payload: FaqsList,
              })
            }
          >
            Edit FAQ
          </Button>
        </div>
        <h3 className="text-[18px] my-3">{FaqsList && FaqsList.title}</h3>
        <h3 className="mb-3 text-[16px]">{FaqsList && FaqsList.heroHeading}</h3>
        <div>{renderFaqQuestion()}</div>
      </Widget>
      <FAQModal />
    </div>
  );
};
export default Index;
