import React from "react";
import TableTitle from "../../../components/Table/components/TableTitle";
import AddDirectNotification from "./AddDirectNotification";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <TableTitle title="Direct Notifications" />
       <AddDirectNotification /> 
    </div>
  );
};

export default TableHeader;
