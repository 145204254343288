import React from "react";
import AddCategoryLinking from "./AddCategoryLinking";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-end flex-wrap mb-3">
      <AddCategoryLinking />
    </div>
  );
};

export default TableHeader;
