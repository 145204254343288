import { Col, Form, Input, Row } from "antd";
import React from "react";
const BroadcastMessageForm = () => {
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title is required",
            },
          ]}
        >
          <Input placeholder="Enter your title" />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          label="Message"
          name="description"
          rules={[{ required: true, message: "Description is required" }]}
        >
          <Input.TextArea rows={5} placeholder="Enter Message" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default BroadcastMessageForm;
