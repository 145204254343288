import DedailModal from "../../../components/Modal/DedailModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NewsForm from "./NewsForm";
import { Form } from "antd";

const ViewNews = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState([]);
  const { initialValue, View_News_Modal } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (initialValue) {
      if (initialValue && initialValue.fileUrl) {
        setProfileImage(initialValue.fileUrl);
      }
    }
    if (View_News_Modal) {
      form.resetFields();
    }
  }, [View_News_Modal]);
  return (
    <>
      {View_News_Modal && initialValue && (
        <DedailModal
          heading="View News"
          modalName={"View_News_Modal"}
          width={"600px"}
        >
          <Form layout="vertical" form={form} initialValues={initialValue}>
            <NewsForm
              setProfileImage={setProfileImage}
              profileImage={profileImage}
              formType={"view"}
              disabled={true}
            />
          </Form>
        </DedailModal>
      )}
    </>
  );
};

export default ViewNews;
