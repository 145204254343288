import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import AgeForm from "./CategoryForm";

const AddCategory = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName="Add_Category_Modal"
      heading="Add Category"
      apiName="addCategories"
      recordName="productCategoryList"
      width={"500px"}
      addButtonName="Add Category"
      extraValueObj={{ type: "product" }}
    >
      <AgeForm form={form}/>
    </AddFormModal>
  );
};

export default AddCategory;
