import React, { useEffect } from "react";
import AuthLayout from "../AuthLayout";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginSpin, loginSuccess } = useSelector((state) => state.AuthR);
  const onsubmit = (values) => {
    dispatch(
      DataRequestAction(
        "POST",
        "login",
        values,
        "Login",
        "",
        "LoginSpin",
        "",
        false
      )
    );
  };
  if (loginSuccess) {
    navigate("/dashboard");
    dispatch({ type: "RESET_AUTH_STATE" });
  }

  useEffect(() => {
    dispatch({ type: "RESET_AUTH_STATE" });
    localStorage.clear();
  }, []);
  return (
    <AuthLayout heading="Welcome Back!" content="Log in to your account">
      <Form onFinish={onsubmit} layout="vertical" className="mt-5">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Enter your email" },
            { type: "email", message: "Enter valid email" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          className="mb-2"
          rules={[{ required: true, message: "Enter your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>
        <div className="flex justify-end mb-4">
          <span className="underline text-primary-brown cursor-pointer">
            Forgot Password
          </span>
        </div>
        <Button
          loading={loginSpin}
          disabled={loginSpin}
          type="primary"
          htmlType="submit"
          className="bg-primary-brown w-full font-semibold"
          size="large"
        >
          Login
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default Index;
