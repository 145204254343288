import React from "react";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const { RangePicker } = DatePicker;
const DateFilter = ({ width = "200px", apiName, recordName, customeQuery }) => {
  const dispatch = useDispatch();
  const CallApi = (data) => {
    dispatch(
      DataGetAction(
        apiName,
        data,
        "FetchRecord",
        recordName,
        "TableSpinner",
        ""
      )
    );
  };
  const checkAndCallApi = (e) => {
    if (e !== null) {
      CallApi({
        query: "search",
        startDate: new Date(e[0]),
        endDate: new Date(e[1]),
        ...customeQuery,
      });
    } else {
      CallApi({ query: "all", ...customeQuery });
    }
  };
  return (
    <RangePicker
      onChange={(e) => checkAndCallApi(e)}
      style={{ width: width }}
      // disabledDate={(current) => {
      //   return current && current > Date.now();
      // }}
    />
  );
};

export default DateFilter;
