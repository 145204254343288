import React from "react";

import { Button, message } from "antd";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import SearchBox from "../../../components/Filters/SearchBox";
import DateFilter from "../../../components/Filters/DateFilter";
import TableTitle from "../../../components/Table/components/TableTitle";
import { useNavigate } from "react-router-dom";

const TableHeader = ({ userId }) => {
  const { bookingList } = useSelector((state) => state.CrudR);
  const navigate = useNavigate();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    if (csvData && csvData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      message.info("Data is not found");
    }
  };

  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <TableTitle title="Booking" />
      <div className="flex items-center justify-end flex-wrap mb-3 gap-3">
        <SearchBox
          placeholder="Id / Customer Name / Service Name"
          apiName="getBookingList"
          recordName="bookingList"
          width="300px"

          customeQuery={{ userId: userId }}
        />
        <DateFilter apiName="getBookingList" recordName="bookingList"  customeQuery={{ userId: userId }}/>
        {userId && <Button
          onClick={() => {
            navigate('/booking', { state: { userId: null } })
            window.location.reload()
          }}
          className="drop-shadow bg-primary-brown"
          type="primary"
        >
          Show All
        </Button>}
        <Button
          onClick={(e) => exportToCSV(bookingList, "Booking")}
          className="drop-shadow bg-primary-brown"
          type="primary"
        >
          Excel download
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
