import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import BannerUploader from "../../../components/Uploader/bannerUploader";
import { useDispatch, useSelector } from "react-redux";
import DetailModal from "../../../components/Modal/DedailModal";
import { DataRequestAction } from "../../../redux/actions/CommonHttp";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState("");
  const { initialValue, Open_Privacy_policy_Page_Modal, fetchingSpinner } =
    useSelector((state) => state.CrudR);
  console.log("===initialValue===", initialValue);
  const onFinish = (values) => {
    values["pageType"] = "privacyPolicy";
    values["bannerImageUrl"] = imageUrl;
    dispatch(
      DataRequestAction(
        "PUT",
        "editPageData",
        values,
        "FetchRecord",
        "PrivacyPolicyList",
        "TableSpinner",
        "Open_Privacy_policy_Page_Modal"
      )
    );
  };

  useEffect(() => {
    if (initialValue && initialValue.bannerImageUrl) {
      setImageUrl(initialValue.bannerImageUrl);
    } else {
      setImageUrl("");
    }
    if (!Open_Privacy_policy_Page_Modal) {
      //   form.resetFields();
      setImageUrl("");
    }
  }, [Open_Privacy_policy_Page_Modal]);
  return (
    <DetailModal
      modalName="Open_Privacy_policy_Page_Modal"
      heading="Privacy policy"
    >
      <Form
        className="mt-3"
        layout="vertical"
        initialValues={initialValue}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Hero Heading"
          name={"heroHeading"}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"title"} label="Heading" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
        <BannerUploader imageUrl={imageUrl} setImageUrl={setImageUrl} />
        <div className="flex items-center justify-end gap-3">
          <Button>Cancel</Button>
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary-brown"
            loading={fetchingSpinner}
            disabled={fetchingSpinner}
          >
            Update
          </Button>
        </div>
      </Form>
    </DetailModal>
  );
};

export default PrivacyPolicy;
