import React, { useState } from "react";
import Table from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import moment from "moment";
import Action from "../../components/Table/components/Action";
import ShowModal from "../../components/Modal/ShowModal";
import DeleteModal from "../../components/Modal/DeleteModal";
import { Modal } from "antd";


const ShortDescriptionCell = ({ description }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const truncatedDescription = description?.length > 50 ? `${description.substring(0, 50)}...` : description;

  return (
    <>
      <span className="capitalize hover:underline" onClick={showModal} style={{ cursor: 'pointer', color: '#722F38' }}>
        {truncatedDescription || "---"}
      </span>
      <Modal
        className="mt-20 text-justify overflow-y-auto"
        title="Description"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="modal-container">
          <p className="modal-content">
            {description || "---"}
          </p>
        </div>
      </Modal>
    </>
  );
};

const Index = () => {
  const renderAction = (record) => {
    return (
      <DeleteModal
        apiName="deleteBroadCast"
        recordName="broadcastList"
        requestType="DeleteRecord"
        selectedItem={record}
        title="Delete"
        content="Are you sure you want to delete this record?"
      />
    );
  };
  let columns = [
    {
      title: "Title",
      render: (record) => <span className="capitalize">{record.title}</span>,
      key: "title",
      width:'200px'
    },
    {
      title: "Description",
      render: (record) => (
       <ShortDescriptionCell description={record?.description}/>
      ),
      key: "description",
      width: "400px",
    },
    {
      title: "Created At",
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdAt",
    },
   
    {
      title: "",
      render: (record) => <Action>{renderAction(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <Table
          columns={columns}
          apiName="getBroadCast"
          recordName="broadcastList"
        />
      </Widget>
    </>
  );
};

export default Index;
