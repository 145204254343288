import React from "react";
import Table from "../../../components/Table";
import EditSubCategory from "./components/EditSubCategory";
import TableHeader from "./components/TableHeader";
import ShowModal from "../../../components/Modal/ShowModal";
import moment from "moment";
import Action from "../../../components/Table/components/Action";
import Widget from "../../../components/Widget";
import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="flex flex-col">
        <ShowModal modalName="EDIT_SubCategory_MODAL" name="Edit" record={record} />
      </div>
    );
  };
  const columns = [
    {
      title: "ID",
      render: (record) => <span>{record.productSubCategoryId}</span>,
      key: "id",
      width: "100px",
    },
    
    {
      title: "Sub Category Name",
      render: (record) => <span className="capitalize">{record.name}</span>,
      key: "subCategoryName",
      width: "250px",
    },
    {
      title: "Category Image",
      render: (record) => <Avatar src={record.categoryImage} size={100} shape="square" icon={<AntDesignOutlined />} />,

      key: "categoryImage",
      width: "150px",
    },
    {
      title: "Created At",
      render: (record) => <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>,
      key: "createdAt",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <div className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg" style={{ border: "1px solid" }}>
      <Widget className="p-5">
        <TableHeader />
        <Table
          apiName="subCategories"
          recordName="productSubCategoryList"
          columns={columns}
          customQuery={{ type: "product" }}
        />
      </Widget>
      <EditSubCategory />
    </div>
  );
};
export default Index;
