import { message } from "antd";
const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const DataRequestAction = (
  method,
  apiName,
  data,
  requestType,
  recordName,
  loaderCase,
  modalName,
  showNotification = true
) => {
  return function (dispatch) {
    RequestProgress(dispatch, loaderCase);
    const token = localStorage.getItem("token");
    try {
      fetch(`${ROOT_URL}/${apiName}`, {
        method: method,
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: token,
        }),
        body: JSON.stringify(data),
      })
        .then((response) => {
          response.json().then((response) => {
            if (response.status === "SUCCESS") {
              if (showNotification) {
                message.success(response.message);
              }
              RequestSuccess(dispatch, requestType, response, recordName);
              RequestModalClose(dispatch, modalName);
            }
            if (response.status === "ERROR") {
              message.error(response.message);
              RequestFail(dispatch, requestType, recordName);
            }
            if (response && response.status === 404) {
              message.error(response.message);
              RequestFail(dispatch, requestType, recordName);
            }
          });
        })
        .catch((err) => {
          console.log("====response err====", err);
          RequestFail(dispatch, requestType, recordName);
          RequestModalClose(dispatch, modalName);
        });
    } catch (error) {
      console.log("====response error====", error);
      RequestFail(dispatch, requestType, recordName);
    }
  };
};
export const DataGetAction = (
  apiName,
  data,
  requestType,
  recordName,
  loaderCase,
  modalName = ""
) => {
  return function (dispatch) {
    RequestProgress(dispatch, loaderCase);
    const token = localStorage.getItem("token");
    let param = "";
    if (data) {
      param = `/${JSON.stringify(data)}`;
    }
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
      APIType: "web",
    };
    try {
      fetch(`${ROOT_URL}/${apiName}${param}`, { headers })
        .then((response) => {
          if (response.status === 401) {
            RequestUnAuthorized(dispatch, response.status);
          }
          if (response.status === 404) {
            message.error(response.message);
            RequestFail(dispatch, requestType, recordName);
          } else {
            response.json().then((response) => {
              RequestSuccess(dispatch, requestType, response, recordName);
              RequestModalClose(dispatch, modalName);
            });
          }
        })
        .catch((err) => {
          RequestFail(dispatch, requestType, recordName);
          RequestModalClose(dispatch, modalName);
        });
    } catch (error) {
      RequestFail(dispatch, requestType, recordName);
      RequestModalClose(dispatch, modalName);
    }
  };
};
function RequestFail(dispatch, type, recordName) {
  dispatch({
    type: `${type}_ERROR`,
    recordName: recordName,
  });
}
function RequestSuccess(dispatch, type, data, recordName) {
  dispatch({
    type: `${type}_SUCCESS`,
    payload: data,
    recordName: recordName,
  });
}
function RequestProgress(dispatch, loaderCase) {
  dispatch({
    type: `${loaderCase}_START`,
  });
}
function RequestModalClose(dispatch, modalName) {
  if (modalName !== "") {
    dispatch({
      type: "Hide_Modal",
      payload: null,
      modalName: modalName,
    });
  }
}
function RequestUnAuthorized(dispatch) {
  dispatch({
    type: "UN_AUTHORIZED",
  });
}
