import React from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageUploader", ImageUploader);
const ReactQuil = ({
  Form,
  name,
  labelName,
  restField,
  readOnly = false,
  required = false,
  message = "",
}) => {
  return (
    <Form.Item
      {...restField}
      name={name}
      label={`${labelName}`}
      rules={[
        {
          required: required,
          message: message,
        },
      ]}
      initialValue=""
    >
      <ReactQuill
        readOnly={readOnly}
        style={{ height: "150px", marginBottom: "40px" }}
        modules={{
          toolbar: [
            [
              "bold",
              "italic",
              "link",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
            ],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "imageBlot",
        ]}
      />
    </Form.Item>
  );
};

export default ReactQuil;
