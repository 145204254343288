import { Form, Image, Input } from "antd";
import React from "react";
import ReactQuil from "../../../components/ReactQuil";
import MultipalUploader from "../../../components/Uploader/MultipalUploader";
const NewsForm = ({ setProfileImage, profileImage, formType, disabled }) => {
  const renderImageView = () => {
    const renderImag = () => {
      if (profileImage && profileImage.length > 0) {
        return profileImage.map((item) => {
          return (
            <Image
              src={item}
              style={{ height: "80px", width: "80px", borderRadius: "10px" }}
            />
          );
        });
      }
    };
    return (
      <div
        style={{ background: "#F5F5F5" }}
        className="h-[100px] px-2 rounded flex items-center justify-start gap-2 w-full overflow-y-hidden overflow-x-auto"
      >
        {renderImag()}
      </div>
    );
  };
  return (
    <>
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Title is required" }]}
      >
        <Input readOnly={disabled} placeholder="Enter your title" />
      </Form.Item>
      <ReactQuil
        readOnly={disabled}
        Form={Form}
        name="description"
        labelName={"Descriptions"}
      />
      <Form.Item
        name="shortDescription"
        label="Short Description"
        rules={[
          {
            required: true,
            message: "Short description is required",
          },
        ]}
      >
        <Input.TextArea
          readOnly={disabled}
          rows={5}
          placeholder="Enter short description"
        />
      </Form.Item>
      {formType !== "view" ? (
        <MultipalUploader
          setImageUrl={setProfileImage}
          imageUrl={profileImage}
        />
      ) : (
        renderImageView()
      )}
    </>
  );
};

export default NewsForm;
