import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const AreaGraph = ({ ProductsSalesGraph }) => {
  return (
    <ResponsiveContainer height={185}>
      <AreaChart
        data={ProductsSalesGraph}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        {/* <Tooltip /> */}
        <XAxis dataKey={"name"} />
        <YAxis />
        <defs>
          <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FDCCB8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffffff" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <Area
          dataKey={"totalPrice"}
          strokeWidth={2}
          stackId="2"
          stroke="#F93330"
          fill="url(#color15)"
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaGraph;
