import React from "react";
import DetailModal from "../../../components/Modal/DedailModal";
import ListTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button } from "antd";
import { renderStatus } from "../../../utils/CommonFunctions";
const ProductDetailModal = () => {
  const dispatch = useDispatch();
  const { initialValue } = useSelector((state) => state.CrudR);
  console.log("==== initialValue ====", initialValue);
  let columns = [
    {
      title: "Merchant",
      render: (record) => {
        const { merchantDetail } = record;
        const { fullName } = merchantDetail && merchantDetail[0] || "----";
        return <span className="flex items-center gap-2">{fullName}</span>;
      },
      key: "name",
    },
    {
      title: "Name",
      render: (record) => (
        <span className="flex items-center gap-2">
          <Avatar size="large" />
          {record.productName}
        </span>
      ),
      key: "name",
    },

    {
      title: "Quantity",
      render: (record) => <span>{record.quantity}</span>,
      key: "quantity",
    },
    {
      title: "Unit Price",
      render: (record) => <span>${record.unitPrice}</span>,
      key: "unitPrice",
    },
    {
      title: "Total Price",
      render: (record) => <span>${record.totalPrice}</span>,
      key: "totalPrice",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
  ];
  return (
    <DetailModal width={"1000px"} modalName="Order_Product_Detail" heading="Product Detail">
      <ListTable apiCall={false} tempData={initialValue} columns={columns} pagination={false}/>
      <div className="flex justify-end mt-[25px]">
        <Button
          onClick={() => dispatch({ type: "Hide_Modal", modalName: "Order_Product_Detail" })}
          className="bg-primary-brown text-[#fff]"
          size="large"
        >
          OK
        </Button>
      </div>
    </DetailModal>
  );
};

export default ProductDetailModal;
