import React from "react";
import TableTitle from "../../../components/Table/components/TableTitle";
import AddNews from "./AddNews";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-between flex-wrap mb-3" style={{ borderBottom: "2px solid #E6E6E6" }}>
      <TableTitle title="News" />
      <AddNews />
    </div>
  );
};

export default TableHeader;
