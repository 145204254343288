import React, { useState } from "react";
import UserForm from "./UserForm";
import { Form } from "antd";
import AddFormModal from "../../../components/Modal/AddFormModal";

const AddUser = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState();
  return (
    <AddFormModal
      form={form}
      modalName="Add_User_Modal"
      apiName="addUser"
      recordName="userList"
      heading="Add User"
      width={"700px"}
      extraValueObj={{ profileImageUrl: profileImage }}
    >
      <UserForm setProfileImage={setProfileImage} profileImage={profileImage} />
    </AddFormModal>
  );
};

export default AddUser;
