import { Tabs } from "antd";
import React from "react";
import Orders from "./orders";
import Services from "./services";

const index = () => {
  const items = [
    {
      key: "1",
      label: "Orders",
      children: <Orders />,
    },
    {
      key: "2",
      label: "Services",
      children: <Services />,
    },
  ];
  return (
    <Tabs defaultActiveKey="1" type="card" className="h-full" items={items} />
  );
};

export default index;
