import React, { useEffect, useState } from "react";
import Widget from "../../components/Widget";
import { Col, Row, Spin } from "antd";
import PlaceholderImg from "../../assets/images/placeholder.webp";
import { FaRegEdit, FaUnlockAlt, FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoCall, IoMailSharp } from "react-icons/io5";
import LoginImage from "../../assets/images/loginScreen.jpg";
import EditFormModal from "./components/EditFormModal";
import AddLinkModal from "./components/AddLinkModal";
import LoginUploader from "../../components/Uploader/LoginUploader";
import ChangePassword from "./components/ChangePassword";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const Index = () => {
  const dispatch = useDispatch();
  const { authUser, profileSpin } = useSelector((state) => state.AuthR);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    dispatch(
      DataGetAction("getProfile", "", "GET_PROFILE", "", "PROFILE_SPIN")
    );
  }, []);
  return (
    <Widget>
      {profileSpin && (
        <div className="h-[200px] flex items-center justify-center">
          <Spin size="large" />
        </div>
      )}
      {!profileSpin && (
        <>
          <div className="p-5" style={{ borderBottom: "1px solid #CCCCCC" }}>
            <h2 className="text-[30px] font-roboto-bold">Setting</h2>
          </div>
          <div className="p-5">
            <Row gutter={[20, 20]}>
              <Col xl={8} lg={8} md={24} xs={24} sm={24}>
                <Widget className="p-5">
                  <div className="flex justify-between items-start">
                    <img
                      src={
                        (authUser && authUser.profileImageUrl) || PlaceholderImg
                      }
                      alt=""
                      className="drop-shadow rounded-xl"
                      style={{ width: "120px", height: "120px" }}
                    />
                    <div className="flex items-center gap-3">
                      <FaUnlockAlt
                        size={26}
                        fill="#FFD164"
                        className="cursor-pointer"
                        onClick={() => {
                          dispatch({
                            type: "Show_Modal",
                            modalName: "Change_User_Old_Password_Modal",
                            payload: authUser,
                          });
                        }}
                      />

                      <FaRegEdit
                        size={26}
                        fill="#FFD164"
                        className="cursor-pointer"
                        onClick={() => {
                          dispatch({
                            type: "Show_Modal",
                            modalName: "Edit_Profile",
                            payload: authUser,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <p className="mt-4 text-primary-brown font-roboto-medium">
                    Full Name
                  </p>
                  <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                    <FaUser fill="#722F38" />
                    {authUser && authUser.fullName}
                  </div>
                  <p className="text-primary-brown font-roboto-medium">Email</p>
                  <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                    <IoMailSharp fill="#722F38" />
                    {authUser && authUser.email}
                  </div>
                  <p className="text-primary-brown font-roboto-medium">Phone</p>
                  <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                    <IoCall fill="#722F38" />
                    {authUser && authUser.phoneNumber}
                  </div>
                </Widget>
              </Col>
              <Col xl={8} lg={8} md={24} xs={24} sm={24}>
                <Widget>
                  <div
                    className="p-5 flex items-center justify-between"
                    style={{ borderBottom: "1px solid #CCCCCC" }}
                  >
                    <h2 className="text-[20px] font-roboto-medium">
                      Login Banner Image
                    </h2>
                    <LoginUploader
                      setImageUrl={setImageUrl}
                      imageUrl={imageUrl}
                    />
                  </div>
                  <div className="lg:h-[338px] h-full p-5">
                    <img
                      src={
                        (authUser && authUser.loginBannerImage) || LoginImage
                      }
                      alt=""
                      className="h-full w-full rounded-lg"
                    />
                  </div>
                </Widget>
              </Col>
              <Col xl={8} lg={8} md={24} xs={24} sm={24}>
                <Widget>
                  <div
                    className="p-5 flex justify-between"
                    style={{ borderBottom: "1px solid #CCCCCC" }}
                  >
                    <h2 className="text-[20px] font-roboto-medium">
                      Follow Us
                    </h2>
                    <FaRegEdit
                      size={26}
                      fill="#FFD164"
                      className="cursor-pointer"
                      onClick={() => {
                        dispatch({
                          type: "Show_Modal",
                          modalName: "Add_Links",
                          payload: authUser,
                        });
                      }}
                    />
                  </div>
                  <div className="p-5 flex flex-col gap-2">
                    <h3 className="text-[20px] font-roboto-medium">Facebook</h3>
                    <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                      {authUser && authUser.facebookLink}
                    </div>
                    <h3 className="text-[20px] font-roboto-medium">
                      Instagram
                    </h3>
                    <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                      {authUser && authUser.instagramLink}
                    </div>
                    <h3 className="text-[20px] font-roboto-medium">
                      Linked In
                    </h3>
                    <div className="h-[40px] w-full border border-primary-brown rounded my-2 font-roboto-medium text-[16px] flex items-center px-2 gap-2">
                      {authUser && authUser.linkedInLink}
                    </div>
                  </div>
                </Widget>
              </Col>
            </Row>
          </div>
          <EditFormModal />
          <AddLinkModal />
          <ChangePassword />
        </>
      )}
    </Widget>
  );
};

export default Index;
