import React from "react";
import SearchBox from "../../../components/Filters/SearchBox";
import DateFilter from "../../../components/Filters/DateFilter";
import SelectedFilter from "../../../components/Filters/SelectedFilter";
import TableTitle from "../../../components/Table/components/TableTitle";
import AddBanner from "./AddBanner";

const TableHeader = () => {
  let options = [
    { label: "All", value: "all" },
    { label: "Activate", value: "activate" },
    { label: "De-Activate", value: "deActivate" },
  ];
  return (
    <div className="flex items-center justify-between flex-wrap mb-3" style={{ borderBottom: "2px solid #E6E6E6" }}>
      <TableTitle title="Banners" />
      <div className="flex items-center gap-2 flex-wrap">
        <SearchBox
          placeholder="Id / Title"
          apiName="getBanner"
          recordName="bannerList"
        />
        <DateFilter apiName="getBanner" recordName="bannerList" />
        <SelectedFilter
          placeholder="Status"
          width="150px"
          options={options}
          apiName="getBanner"
          recordName="bannerList"
        />
        <AddBanner />
      </div>
    </div>
  );
};

export default TableHeader;
