import { Button, Form, Input, Upload } from "antd";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
const CategoryForm = ({ form }) => {
  const token = localStorage.getItem("token");

  const handleChange = (info) => {
    console.log("===== info =====", info);
    if (info.file.status === "done") {
      form.setFieldValue("categoryImage", info.file.response.url.location);
    }
  };

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    onChange: handleChange,
  };

  return (
    <>
      <Form.Item
        name="name"
        label="Category"
        rules={[
          {
            required: true,
            message: "Category is required",
          },
        ]}
      >
        <Input placeholder="Enter category" />
      </Form.Item>
      <Form.Item name="categoryImage" label="Category Image" rules={[{ required: true }]}>
        <Upload listType="picture" maxCount={1} {...ImageProps}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </>
  );
};

export default CategoryForm;
