import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import ServicesCategoryForm from "./ServicesCategoryForm";

const AddServicesCategory = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName="Add_Services_Category_Modal"
      heading="Add Category"
      apiName="addCategories"
      recordName="servicesCategoryList"
      width={"500px"}
      addButtonName="Add Category"
      extraValueObj={{ type: "service" }}
    >
      <ServicesCategoryForm form={form} />
    </AddFormModal>
  );
};

export default AddServicesCategory;
