import { Tabs } from "antd";
import React, { useState } from "react";
import Listed from "./Listed";
import DeListed from "./DeListed";

const Index = () => {
  
  const [selectedTab, setSelectedTab] = useState(1);

  const items = [
    {
      key: "1",
      label: "Listed",
      children: <Listed selectedTab={selectedTab}/>,
    },
    {
      key: "2",
      label: "De-Listed",
      children: <DeListed selectedTab={selectedTab}/>,
    },
  ];
  return (
    <Tabs
      defaultActiveKey={selectedTab}
      type="card"
      className="h-full"
      items={items}
      onChange={(key) => {
        setSelectedTab(key);
      }}
    />
  );
};

export default Index;
