import React from "react";
import BroadcastMessageForm from "./BroadcastMessageForm";
import { Form } from "antd";
import AddFormModal from "../../../components/Modal/AddFormModal";

const AddBroadcastMessage = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      form={form}
      modalName='Add_Broadcast_Modal'
      heading="Add Broadcast"
      apiName="addBroadCast"
      recordName="broadcastList"
      width={"600px"}
      addButtonName="Add Broadcast"
    >
      <BroadcastMessageForm />
    </AddFormModal>
  );
};

export default AddBroadcastMessage;
