import { Tag } from "antd";
import momentTimeZone from "moment-timezone";
import moment from "moment";

export const renderStatus = (status) => {
  if (status === "active") {
    return (
      <Tag color="green" className="px-3">
        Active
      </Tag>
    );
  }
  if (status === "activate") {
    return <Tag color="green">Activate</Tag>;
  }
  if (status === "deActivate") {
    return <Tag color="red">De-Activate</Tag>;
  }
  if (status === "block") {
    return <Tag color="red">Block</Tag>;
  }
  if (status === "deleted") {
    return <Tag color="orange">Delete</Tag>;
  }
  if (status === "pending") {
    return <Tag color="blue">Pending</Tag>;
  }
  if (status === "returnedBack") {
    return <Tag color="blue">Returned Back</Tag>;
  }
  if (status === "confirmed") {
    return <Tag color="orange">Confirmed</Tag>;
  }
  if (status === "refunded") {
    return <Tag color="green">Refunded</Tag>;
  }
  if (status === "approved") {
    return <Tag color="green">Approved</Tag>;
  }
  if (status === "initated") {
    return <Tag color="blue">Initated</Tag>;
  }
  if (status === "inProcess" || status === "in-process") {
    return <Tag color="yellow">In Process</Tag>;
  }
  if (status === "deliverd") {
    return <Tag color="green">Deliverd</Tag>;
  }
  if (status === "expired") {
    return <Tag color="blue">Expired</Tag>;
  }
  if (status === "cancelled") {
    return <Tag color="red">Cancelled</Tag>;
  }
  if (status === "booked") {
    return <Tag color="gold">Booked</Tag>;
  }
  if (status === "completed") {
    return <Tag color="green">Completed</Tag>;
  }

  if (status === "paid") {
    return <Tag color="green">Paid</Tag>;
  }
  if (status === "inReview") {
    return <Tag color="blue">In Review</Tag>;
  }
  return <Tag color="blue">{capitalize(status)}</Tag>;
};

export const capitalize = (str) => {
  // Split the string into an array of words
  if (str) {
    let words = str.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back into a string and return
    return words.join(" ");
  }
};

export const getTimeSlot = (timeSlot, duration) => {
  const startMoment = momentTimeZone.utc(timeSlot).local();
  const endMoment = startMoment.clone().add(duration, "minutes");
  let label = `${startMoment.format("hh:mm A")} - ${endMoment.format("hh:mm A")}`;
  return label;
};
export const getLocalTime = (timeSlot) => {
  const startMoment = momentTimeZone.utc(timeSlot).local().format("hh:mm A");
  return startMoment;
};
export const getLocalDate = (date) => {
  const startMoment = momentTimeZone.utc(date).local().format(process.env.REACT_APP_DATE_FORMATE);
  return startMoment;
};

export const getUTCTimeSlot = (interval) => {
  console.log("===== obj === interval ====", interval);
  let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTimeString = `${currentDate} ${interval}`;
  let localTime = moment.tz(dateTimeString, "YYYY-MM-DD hh:mm A", localTimeZone);
  let timeSlot = localTime.utc().format();
  return timeSlot;
};
