import React from "react";
import DirectNotificationForm from "./DirectNotificationForm";
import { Form } from "antd";
import AddFormModal from "../../../components/Modal/AddFormModal";

const AddDirectNotification = () => {
  const [form] = Form.useForm();
  return (
    <AddFormModal
      form={form}
      modalName="Add_Notification_Modal"
      heading="Send Direct Notifications"
      apiName="sendDirectMessage"
      recordName="directMessageList"
      width={"600px"}
      addButtonName="Send Notification"
    >
      <DirectNotificationForm form={form} />
    </AddFormModal>
  );
};

export default AddDirectNotification;
