import { Form, Input } from "antd";
import React from "react";

const AgeForm = () => {
  return (
    <Form.Item
      name="genderType"
      label="Gender"
      rules={[
        {
          required: true,
          message: "Gender is required",
        },
      ]}
    >
      <Input placeholder={"Enter your pet gender"} />
    </Form.Item>
  );
};

export default AgeForm;
