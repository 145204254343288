import React from "react";
import { Form } from "antd";
import AddFormModal from "../../../../components/Modal/AddFormModal";
import SubCategoryForm from "./SubCategoryForm";

const AddCategory = () => {
  const [form] = Form.useForm();

  return (
    <AddFormModal
      form={form}
      modalName="Add_SubCategory_Modal"
      heading="Add Sub Category"
      apiName="addSubCategories"
      recordName="productSubCategoryList"
      width={"500px"}
      addButtonName="Add Sub Category"
      extraValueObj={{ type: "product" }}
    >
      <SubCategoryForm form={form} />
    </AddFormModal>
  );
};

export default AddCategory;
