import React from "react";
import { useDispatch } from "react-redux";

const ShowModal = ({ record, modalName, name,className }) => {
  const dispatch = useDispatch();
  return (
    <span
      className={`cursor-pointer ${className}`}
      onClick={() =>
        dispatch({
          type: "Show_Modal",
          modalName: modalName,
          payload: record,
        })
      }
    >
      {name}
    </span>
  );
};

export default ShowModal;
