import React from "react";
import backgroundBlue from "../../../assets/images/blueBackground.jpg";
import backgroundOrange from "../../../assets/images/orangeBackground.png";
import { BsCart4, BsFillCartCheckFill, BsCardList } from "react-icons/bs";
import { FaCalendarCheck, FaDownLong, FaHandshake, FaListCheck } from "react-icons/fa6";
import { MdFastfood } from "react-icons/md";
import { TiShoppingCart } from "react-icons/ti";

import NavCard from "../../../components/Layout/components/NavCard";
const TotalServiceAndOrderCard = ({ totalServices, totalOrders, menuCardData }) => {
  const renderTabsLikeButton = (title, count, backgroundBar, background, showIcon = true) => {
    return (
      <div className="relative px-5">
        <div style={{ background: backgroundBar }} className="grid grid-cols-2 gap-5 mb-8 rounded-lg">
          <div className="relative" style={{ background: background }}>
            <p className="text-center border border-[#fff] text-[16px] py-1 rounded-lg text-[#fff]">{title}</p>
            <div className="absolute top-8 left-[45%]">{showIcon && <FaDownLong size={24} fill="white" className="my-2" />}</div>
          </div>

          <div className="bg-[white] py-1 ml-1 rounded-lg">
            <p className="text-center text-[16px]">{count}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div
        className="bg-no-repeat bg-cover bg-center w-full rounded py-8 mb-4 drop-shadow-xl bg-[#092866]"
        style={{ backgroundImage: `url(${backgroundBlue})` }}
      >
        <div className="border border-[#fff] rounded-full  w-[80px] h-[80px] flex items-center justify-center mx-auto ">
          <BsFillCartCheckFill fill="#fff" size={40} />
        </div>
        <h6 className="font-roboto-medium text-[#fff] text-center my-4 text-[18px]">Total Services</h6>
        {renderTabsLikeButton("Booking", `${totalServices && totalServices.booking}`, "#367BDF", "#4188F2")}

        {renderTabsLikeButton("Confirmed", `${totalServices && totalServices.confirmed}`, "#367BDF", "#4188F2")}
        {renderTabsLikeButton("Completed", `${totalServices && totalServices.completed}`, "#367BDF", "#4188F2", false)}

        <div>
          <div className="bg-[#FFFFFF] w-[200px] rounded-lg ml-20 mb-5">
            <NavCard
              title="Service Posting:"
              count={menuCardData && menuCardData.totalService}
              iconColorbg="#E4D3DF"
              color="#79155B"
              icon={<FaHandshake size={20} />}
              routeName="/services"
            />
          </div>
          <div className="bg-[#FFFFFF] w-[200px] rounded-lg ml-20">
            <NavCard
              title="Booking:"
              count={menuCardData?.totalBooking || 0}
              iconColorbg="#F2DDD0"
              color="#C94F00"
              icon={<FaCalendarCheck size={20} />}
              routeName="/booking"
            />
          </div>
        </div>
      </div>

      <div
        className="bg-no-repeat bg-cover bg-center w-full rounded py-8 drop-shadow-xl bg-[#092866]"
        style={{ backgroundImage: `url(${backgroundOrange})` }}
      >
        <div className="border border-[#fff] rounded-full w-[80px] h-[80px] flex items-center justify-center mx-auto ">
          <BsCart4 size={40} fill="#fff" />
        </div>
        <h6 className="font-roboto-medium text-[#fff] text-center my-4 text-[18px]">Total orders</h6>
        {renderTabsLikeButton("Orders", `${totalOrders && totalOrders.orders}`, "#C94F00", "#DB6500")}
        {renderTabsLikeButton("Confirmed", `${totalOrders && totalOrders.confirmed}`, "#C94F00", "#DB6500")}
        {renderTabsLikeButton("Completed", `${totalOrders && totalOrders.completed}`, "#C94F00", "#DB6500", false)}
        <div className=" justify-center items-center">
          <div className="bg-[#FFFFFF] w-[200px] rounded-lg ml-20 mb-5">
            <NavCard
            width={300}
              title="Product Posting:"
              count={menuCardData && menuCardData.totalProduct}
              iconColorbg="#F2DDD0"
              color="#C94F00"
              routeName="/petProductPosting"
              icon={<MdFastfood size={20} />}
            />
          </div>
          <div className="bg-[#FFFFFF] w-[200px] rounded-lg ml-20">
            <NavCard
            width={300}
              title="Order:"
              count={menuCardData && menuCardData.totalOrder}
              iconColorbg="#F2DDD0"
              color="#C94F00"
              routeName="/orders"
              icon={<FaListCheck size={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalServiceAndOrderCard;
