import React from "react";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";
const { Search } = Input;

const SearchBox = ({
  width = "220px",
  placeholder = "placeholder",
  apiName,
  recordName,
  customeQuery,
}) => {
  const dispatch = useDispatch();
  const CallApi = (data) => {
    dispatch(
      DataGetAction(
        apiName,
        data,
        "FetchRecord",
        recordName,
        "TableSpinner",
        ""
      )
    );
  };
  const onSearch = (e) => {
    if (e !== "") {
      CallApi({ query: "search", name: e, ...customeQuery });
    } else {
      CallApi({ query: "all", ...customeQuery });
    }
  };
  return (
    <Search
      onSearch={onSearch}
      style={{ width: width }}
      placeholder={placeholder}
      allowClear
    />
  );
};

export default SearchBox;
