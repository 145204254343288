import React from "react";
import DedailModal from "../../../components/Modal/DedailModal";
import { useSelector } from "react-redux";

const ViewOtp = () => {
  const { initialValue } = useSelector((state) => state.CrudR);
  return (
    <DedailModal modalName="Show_OTP_modal" width={"300px"}>
      <div className="mb-4 mt-10 flex justify-center">
        <span className="font-bold text-[26px]">{initialValue && initialValue.phoneNumberOtp}</span>
      </div>
    </DedailModal>
  );
};

export default ViewOtp;
