import React from "react";
import SearchBox from "../../../components/Filters/SearchBox";
import DateFilter from "../../../components/Filters/DateFilter";
import SelectedFilter from "../../../components/Filters/SelectedFilter";
import { Button } from "antd";
import TableTitle from "../../../components/Table/components/TableTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TableHeader = ({ userId }) => {
  const { orderForAdminList } = useSelector((state) => state.CrudR);
  const navigate = useNavigate();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  let options = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Block", value: "block" },
  ];
  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <TableTitle title="Merchant's Orders" />
      <div className="flex items-center gap-2 flex-wrap">
        <SearchBox
          placeholder="Id / Service name"
          apiName="getOrderForAdmin"
          recordName="orderForAdminList"
          customeQuery={{ userId: userId }}
        />
        <DateFilter apiName="getOrderForAdmin" recordName="orderForAdminList" customeQuery={{ userId: userId }} />
        <SelectedFilter
          placeholder="Filter by status"
          width="150px"
          options={options}
          apiName="getOrderForAdmin"
          recordName="orderForAdminList"
          customeQuery={{ userId: userId }}
        />

        <Button onClick={(e) => exportToCSV(orderForAdminList, "ServiceList")} className="drop-shadow bg-primary-brown" type="primary">
          Excel download
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
