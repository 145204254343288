import React from "react";
import SearchBox from "../../../components/Filters/SearchBox";
import TableTitle from "../../../components/Table/components/TableTitle";

const TableHeader = () => {
  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <TableTitle title="Keyword Searches" />
      <div className="flex items-center gap-2 flex-wrap">
        <SearchBox
          placeholder="Enter Keyword"
          apiName="getSearchWord"
          recordName="searchList"
        />
      </div>
    </div>
  );
};

export default TableHeader;
