import React, { useEffect, useState } from "react";
import Widget from "../../../components/Widget";
import { Button, Empty, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import SocialMediaForm from "./SocialMediaFrom";

const Index = () => {
  const dispatch = useDispatch();
  const { socialMediaList } = useSelector((state) => state.CrudR);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  useEffect(() => {
    dispatch(
      DataGetAction(
        "getPageData",
        { pageType: "socialMedia" },
        "FetchRecord",
        "socialMediaList"
      )
    );
  }, [dispatch]);

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Social Media Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <a href={`https://${text}`} target="_blank" rel="noopener noreferrer">{text}</a>,
    },
   
  ];

  const socialMediaData = socialMediaList?.socialMedia || [];

  return (
    <div
      className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg"
      style={{ border: "1px solid" }}
    >
      <Widget className="p-5">
        <div className="flex justify-end">
        <Button
          className="bg-primary-brown mb-3"
          type="primary"
          onClick={() => handleEdit(socialMediaData)}
        >
          Edit Links
        </Button>
        </div>
        {socialMediaData.length > 0 ? (
          <Table columns={columns} dataSource={socialMediaData} rowKey="_id" />
        ) : (
          <div className="my-10 flex items-center justify-center">
            <Empty />
          </div>
        )}
      </Widget>
      {isModalVisible && (
        <SocialMediaForm
          visible={isModalVisible}
          onClose={handleCloseModal}
          initialValue={socialMediaList} // Passing the whole object for editing
        />
      )}
    </div>
  );
};

export default Index;
