import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";

const Index = ({
  columns,
  apiName,
  customQuery,
  recordName,
  apiCall = true,
  tempData,
  pagination = true,
  rowClassName,
  className,
  scroll,
  customCondition = null,
}) => {
  const dispatch = useDispatch();
  const crudProps = useSelector((state) => state.CrudR);
  const callApi = () => {
    console.log("==== customQuery ====", customQuery)
    if (apiCall) {
      dispatch(DataGetAction(apiName, { query: "all", ...customQuery }, "FetchRecord", recordName, "TableSpinner", ""));
    }
  };
  useEffect(callApi, [customCondition]);

  return (
    <Table
      loading={crudProps.fetchingSpinner}
      columns={columns}
      dataSource={(crudProps && crudProps[recordName]) || tempData}
      pagination={pagination}
      className={className}
      rowClassName={rowClassName}
      scroll={scroll}
    />
  );
};

export default Index;
