import React from "react";
import ListedList from "../../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../../components/Widget";
import moment from "moment";
import { renderStatus } from "../../../utils/CommonFunctions";
import { Avatar } from "antd";
import placeholder from "../../../assets/images/placeholder.webp";
import DeleteModal from "../../../components/Modal/DeleteModal";
import Action from "../../../components/Table/components/Action";
import { useLocation } from "react-router-dom";
import {  CheckOutlined } from "@ant-design/icons";

const Index = ({ selectedTab }) => {
  const location = useLocation(); 
  const renderAction = (record) => {
    console.log("===== record selected=====", record);
    return (
      <div className="flex flex-col">
        {/* <DeleteModal
          apiName=""
          recordName=""
          title="Delete"
          content="Are you sure you want to delete this record"
          selectedItem={{ _id: record._id }}
          buttonName={"Delete"}
        /> */}
        <DeleteModal
           showButton={true}
           icon={<CheckOutlined />}
          method="PUT"
          apiName="editProduct"
          recordName="productDeList"
          requestType="DeleteRecord"
          title="List Product"
          content="Are you sure you want to again list the product"
          selectedItem={{ _id: record._id, status: "active" }}
          buttonName="List Product"
        />
      </div>
    );
  };

  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.productId}</span>,
      key: "userId",
      width: "80px",
    },
    {
      title: "Product Name",
      render: (record) => (
        <span className="flex items-center gap-2">
          <Avatar />
          {record.productName}
        </span>
      ),
      key: "productName",
    },
    {
      title: "Merchant's Name",
      render: (record) => (
        <span className="capitalize flex items-center gap-2">
          <Avatar src={record.profileImageUrl || placeholder} />
          {record.fullName || "---"}
        </span>
      ),
      key: "merchantName",
    },
    {
      title: "Email",
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
    },
    {
      title: "Phone",
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
    },
    {
      title: "Category",
      render: (record) => <span>{record.category || "---"}</span>,
      key: "category",
    },
    // {
    //   title: "Amount",
    //   render: (record) => <span>${record.totalPrice || "---"}</span>,
    //   key: "unitPrice",
    // },
    {
      title: "Date",
      render: (record) => <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>,
      key: "date",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => renderAction(record),
      key: "action",
      width: "50px",
    },
  ];

  return (
    <div className="w-full h-full mt-[-16px] rounded-b-lg rounded-r-lg" style={{ border: "1px solid" }}>
      <Widget className="p-5">
        <TableHeader userId={location.state?.userId}/>
        <ListedList
          columns={columns}
          apiName="getProduct"
          recordName="productDeList"
          customQuery={{ status: "deListed",  userId: location.state?.userId  }}
          customCondition={selectedTab}
        />
      </Widget>
    </div>
  );
};

export default Index;
