import React from "react";
import DetailModal from "../../components/Modal/DedailModal";
import { useSelector } from "react-redux";

const ShowLinkClick = () => {
  const { initialValue } = useSelector((state) => state.CrudR);

  console.log("==init==", initialValue);
  const renderLinks = () => {
    if (initialValue && initialValue.length > 0) {
      return initialValue.map((item) => {
        return (
          <div className="flex items-center justify-between">
            <span className="font-roboto-regular capitalize">{item.name}</span>
            <span className="font-roboto-regular">{item.count}</span>
          </div>
        );
      });
    }
  };
  return (
    <DetailModal width={400} heading="Post Link" modalName={"View_Links_MODAL"}>
      <div className="flex items-center justify-between my-3">
        <span className="font-roboto-bold">Link Name</span>
        <span className="font-roboto-bold">Clicks</span>
      </div>
      {renderLinks()}
    </DetailModal>
  );
};

export default ShowLinkClick;
