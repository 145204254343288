import { Tabs } from "antd";
import React from "react";
import Age from "./Age";
import Color from "./Color";
import Gender from "./Gender";
import Category from "./Category";

const index = () => {
  const items = [
    {
      key: "1",
      label: "Age",
      children: <Age />,
    },
    {
      key: "2",
      label: "Color",
      children: <Color />,
    },
    {
      key: "3",
      label: "Gender",
      children: <Gender />,
    },
    {
      key: "4",
      label: "Category",
      children: <Category />,
    },
  ];
  return (
    <Tabs defaultActiveKey="1" type="card" className="h-full" items={items} />
  );
};

export default index;
