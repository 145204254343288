import { Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { DataGetAction } from "../../redux/actions/CommonHttp";

const SelectedFilter = ({
  options,
  placeholder = "placeholder",
  width = "200px",
  apiName,
  recordName,
  customeQuery,
}) => {
  const dispatch = useDispatch();
  const CallApi = (data) => {
    dispatch(
      DataGetAction(
        apiName,
        data,
        "FetchRecord",
        recordName,
        "TableSpinner",
        ""
      )
    );
  };
  const checkAndCallApi = (e) => {
    if (e !== "all" && e !== undefined) {
      CallApi({ query: "search", key: "status", value: e, ...customeQuery });
    } else {
      CallApi({ query: "all", ...customeQuery });
    }
  };
  return (
    <Select
      onChange={(e) => checkAndCallApi(e)}
      options={options}
      placeholder={placeholder}
      style={{ width: width }}
      allowClear
    />
  );
};

export default SelectedFilter;
