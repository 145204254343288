import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "../screens/Dashboard";
import User from "../screens/User";
import Merchant from "../screens/Merchant";
import PetPosting from "../screens/PetPosting";
import Banner from "../screens/Banner";
import BroadcastMessage from "../screens/BroadcastMessage";
import PetPostSetting from "../screens/PostSetting";
import DirectNotification from "../screens/DirectNotification";
import News from "../screens/News";
import Pages from "../screens/Pages";
import PetProducts from "../screens/PetProducts";
import Orders from "../screens/Orders";
import Services from "../screens/Services";
import ProductPostSetting from "../screens/ProductPostSetting";
import ServicesPostSetting from "../screens/ServicesPostSetting";
import SearchKeywords from "../screens/SearchKeywords";
import SearchKeywordsDetail from "../screens/SearchKeywords/UserDetail";
import ProfileSetting from "../screens/ProfileSetting";
import RefundAndCancellation from "../screens/RefundAndCancellation";
import RefundOrder from "../screens/RefundOrder";
import ViewUser from "../screens/PetPosting/ViewUser";
import Booking from "../screens/Booking";
import ReceivedMerchantOrder from "../screens/ReceivedMerchantOrders"
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user" element={<User />} />
        <Route path="/merchant" element={<Merchant />} />
        <Route path="/posts" element={<PetPosting />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/news" element={<News />} />
        <Route path="/broadcastMessage" element={<BroadcastMessage />} />
        <Route path="/petPostSetting" element={<PetPostSetting />} />
        <Route path="/petProductPosting" element={<PetProducts />} />
        <Route path="/directNotification" element={<DirectNotification />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/services" element={<Services />} />
        <Route path="/petProductSetting" element={<ProductPostSetting />} />
        <Route path="/petServicesSetting" element={<ServicesPostSetting />} />
        <Route path="/search" element={<SearchKeywords />} />
        <Route
          path="/keywordSearch/userDetail"
          element={<SearchKeywordsDetail />}
        />
        <Route
          path="/refundOrders"
          element={<RefundOrder />}
        />
        <Route
          path="/cancellation"
          element={<RefundAndCancellation />}
        />
        <Route path="/setting" element={<ProfileSetting />} />
        <Route path="/viewUsers" element={<ViewUser />} />
        <Route path="/merchantOrderForAdmin" element={<ReceivedMerchantOrder/>} />

        <Route path="/" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
