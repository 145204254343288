// import React from "react";
// import SearchBox from "../../../components/Filters/SearchBox";
// import DateFilter from "../../../components/Filters/DateFilter";
// import SelectedFilter from "../../../components/Filters/SelectedFilter";
// import { Button } from "antd";
// import TableTitle from "../../../components/Table/components/TableTitle";
// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

// const TableHeader = ({ userId = null }) => {
//   const { orderList } = useSelector((state) => state.CrudR);
//   const navigate = useNavigate();
//   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";
//   const exportToCSV = (csvData, fileName) => {
//     const ws = XLSX.utils.json_to_sheet(csvData);
//     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//     const data = new Blob([excelBuffer], { type: fileType });
//     FileSaver.saveAs(data, fileName + fileExtension);
//   };
//   let options = [
//     { label: "All", value: "all" },
//     { label: "Pending", value: "pending" },
//     { label: "In Process", value: "in-process" },
//     { label: "Completed", value: "completed" },
//   ];
//   return (
//     <div className="flex items-center justify-between flex-wrap mb-3">
//       <TableTitle title="Orders" />
//       <div className="flex items-center gap-2 flex-wrap">
//         <SearchBox placeholder="Id / Name" apiName="getOrderList" recordName="orderList" customeQuery={{ userId: userId }}/>
//         <DateFilter apiName="getOrderList" recordName="orderList" customeQuery={{ userId: userId }}/>
//         <SelectedFilter
//           placeholder="Filter by status"
//           width="150px"
//           options={options}
//           apiName="getOrderList"
//           recordName="orderList"
//           customeQuery={{ userId: userId }}
//         />
//         {userId && <Button
//           onClick={() => {
//             navigate('/orders', { state: { userId: null } })
//             window.location.reload()
//           }}
//           className="drop-shadow bg-primary-brown"
//           type="primary"
//         >
//           Show All
//         </Button>}
//         <Button
//           onClick={(e) => exportToCSV(orderList, "orders")}
//           className="drop-shadow bg-primary-brown"
//           type="primary"
//         >
//           Excel download
//         </Button>
//       </div>
//     </div >
//   );
// };

// export default TableHeader;



import React from "react";
import SearchBox from "../../../components/Filters/SearchBox";
import DateFilter from "../../../components/Filters/DateFilter";
import SelectedFilter from "../../../components/Filters/SelectedFilter";
import { Button } from "antd";
import TableTitle from "../../../components/Table/components/TableTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TableHeader = ({ userId = null, merchantId = null }) => {
  const { orderList } = useSelector((state) => state.CrudR);
  const navigate = useNavigate();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // Determine the custom query based on the state values
  let customQuery = {};
  
  if (userId && merchantId) {
    customQuery = { userId, merchantId };
  } else if (userId) {
    customQuery = { userId };
  } else if (merchantId) {
    customQuery = { merchantId };
  }

  let options = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "In Process", value: "in-process" },
    { label: "Completed", value: "completed" },
  ];

  return (
    <div className="flex items-center justify-between flex-wrap mb-3">
      <TableTitle title="Orders" />
      <div className="flex items-center gap-2 flex-wrap">
        <SearchBox placeholder="Id / Name" apiName="getOrderList" recordName="orderList" customeQuery={customQuery} />
        <DateFilter apiName="getOrderList" recordName="orderList" customeQuery={customQuery} />
        <SelectedFilter
          placeholder="Filter by status"
          width="150px"
          options={options}
          apiName="getOrderList"
          recordName="orderList"
          customeQuery={customQuery}
        />
        {(userId || merchantId) && (
          <Button
            onClick={() => {
              navigate('/orders', { state: { userId: null, merchantId: null } });
              window.location.reload();
            }}
            className="drop-shadow bg-primary-brown"
            type="primary"
          >
            Show All
          </Button>
        )}
        <Button
          onClick={() => exportToCSV(orderList, "orders")}
          className="drop-shadow bg-primary-brown"
          type="primary"
        >
          Excel download
        </Button>
      </div>
    </div>
  );
};

export default TableHeader;
