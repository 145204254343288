import { Tabs } from "antd";
import React from "react";
import ProductCategory from "./ProductCategory";
import ProductSubCategory from './ProductSubCategory'
import CategoryLinking from './CategoryLinking'
const index = () => {
  const items = [
    {
      key: "1",
      label: "Product Category",
      children: <ProductCategory />,
    },
    {
      key: "2",
      label: "Product Sub Category",
      children: <ProductSubCategory />,
    },
    {
      key: "3",
      label: "Category Linking",
      children: <CategoryLinking />,
    },
  ];
  return (
    <Tabs defaultActiveKey="1" type="card" className="h-full" items={items} />
  );
};

export default index;
