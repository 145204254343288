import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";

const EditFormModal = ({
  heading,
  children,
  form,
  method = "PUT",
  apiName,
  requestType = "EditRecord",
  recordName,
  initialData,
  modalName,
  loaderCase = "editFormModalSpin",
  cancelButtonName = "Cancel",
  saveButtonName = "Update",
  spinnerName = "editRecordSpin",
  width,
  extraValueObj,
}) => {
  const dispatch = useDispatch();
  const crudProps = useSelector((state) => state.CrudR);
  const onFinish = (values) => {
    values["_id"] = initialData && initialData._id;
    dispatch(
      DataRequestAction(
        method,
        apiName,
        { ...values, ...extraValueObj },
        requestType,
        recordName,
        loaderCase,
        modalName
      )
    );
  };
  if (crudProps && crudProps.editRecordSuccess) {
    dispatch({ type: "Hide_Modal", modalName: modalName });
    dispatch({ type: "RESET_CRUD_STATE" });
  }
  const closeModal = () => {
    dispatch({ type: "Hide_Modal", modalName: modalName });
    form.resetFields();
  };
  useEffect(() => {
    if ([crudProps[modalName]]) {
      form.setFieldsValue(initialData);
    }
  }, [crudProps[modalName]]);
  return (
    <Modal
      footer={null}
      onCancel={() => closeModal()}
      open={crudProps[modalName]}
      title={heading}
      width={width}
      centered
    >
      <Form
        initialValues={initialData}
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        {children}
        <div
          className="pt-2 gap-2 flex justify-end"
          style={{ borderTop: "1px solid #DEDEDE" }}
        >
          <Button onClick={() => closeModal()}>{cancelButtonName}</Button>
          <Button
            disabled={crudProps && crudProps[spinnerName]}
            loading={crudProps && crudProps[spinnerName]}
            htmlType="submit"
            type="primary"
            className="bg-primary-brown"
            style={{ boxShadow: "none" }}
          >
            {saveButtonName}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default EditFormModal;
