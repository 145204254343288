import React from "react";
import Table from "../../components/Table";
import TableHeader from "./components/TableHeader";
import Widget from "../../components/Widget";
import ShowModal from "../../components/Modal/ShowModal";
import { Avatar } from "antd";
import ViewNotification from "./components/ViewNotification";
import ViewUsers from "./components/ViewUsers";
const Index = () => {
  const renderUsers = (record) => {
    if (record && record.userDetails && record.userDetails.length > 0) {
      return record.userDetails.map((item, index) => {
        if (index <= 4) {
          return <Avatar key={index} src={item.profileImageUrl} />;
        }
        return null;
      });
    }
    return null;
  };

  const renderView = (record) => {
    return (
      <ShowModal
        modalName="View_Notification_Modal"
        name="View"
        record={record}
        className="text-primary-brown"
      />
    );
  };
  let columns = [
    {
      title: "Id",
      render: (record) => <span>{record.incrementalId}</span>,
      key: "Id",
    },
    {
      title: "Title",
      render: (record) => (
        <span className="capitalize">{record.title || "---"}</span>
      ),
      key: "title",
    },
    {
      title: "Type",
      render: (record) => (
        <span className="capitalize">{record.postType || "---"}</span>
      ),
      key: "postType",
    },
    {
      title: "Post Id",
      render: (record) => <span>{record.postId}</span>,
      key: "postId",
    },
    {
      title: "Notification Text",
      render: (record) => (
        <span>
          {record.notificationText && record.notificationText.length > 70
            ? record.notificationText.slice(0, 70) + "..."
            : record.notificationText}
          {record.notificationText &&
            record.notificationText.length > 70 &&
            renderView(record)}
        </span>
      ),
      key: "notificationText",
    },
    {
      title: "Users",
      render: (record) => (
        <span className="flex items-center">
          <Avatar.Group>{renderUsers(record)}</Avatar.Group>{" "}
          <ShowModal
            modalName="View_User_Notification_Modal"
            name="View"
            record={record}
            className="text-primary-brown"
          />
        </span>
      ),
      key: "Users",
    },
  ];

  return (
    <>
      <Widget className="p-5">
        <TableHeader />
        <Table
          columns={columns}
          apiName="getDirectMessageList"
          recordName="directMessageList"
        />
      </Widget>
      <ViewNotification />
      <ViewUsers />
    </>
  );
};

export default Index;
