import { Avatar } from "antd";
import dummy from "../../../assets/images/Logo.png"
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logoutSuccess, authUser } = useSelector((state) => state.AuthR);
  console.log("===authUser===", authUser);
  const logout = () => {
    console.log("=== call===");
    dispatch({ type: "Log_out" });
  };

  if (logoutSuccess) {
    navigate("/login");
  }

  const items = [
    {
      key: "1",
      label: <div onClick={() => navigate("/setting")}>Profile Setting</div>,
    },
    {
      key: "4",
      danger: true,
      label: <div onClick={() => logout()}>Log out</div>,
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
      className="cursor-pointer"
    >
      <div className="flex items-center gap-1 w-[196px]">
        <Avatar src={authUser?.profileImageUrl || dummy}/>
        <h6 className="font-roboto-medium text-[14px]">{authUser && authUser.fullName}</h6>
        <BiChevronDown />
      </div>
    </Dropdown>
  );
};

export default UserInfo;
