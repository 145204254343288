import React, { useEffect } from "react";
import EditFormModal from "../../../../components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import { Form } from "antd";
import GenderForm from "./GenderForm";

const EditGender = () => {
  const [form] = Form.useForm();
  const { initialValue, EDIT_GENDER_MODAL } = useSelector((state) => state.CrudR);

  useEffect(() => {
    if (EDIT_GENDER_MODAL) {
      form.resetFields();
    }
  }, [EDIT_GENDER_MODAL]);
  return (
    <>
      {EDIT_GENDER_MODAL && initialValue && (
        <EditFormModal
          form={form}
          apiName="updateGender"
          recordName="genderList"
          requestType="EditRecord"
          heading="Edit Gender"
          modalName={"EDIT_GENDER_MODAL"}
          initialData={initialValue}
          width={"500px"}
        >
          <GenderForm />
        </EditFormModal>
      )}
    </>
  );
};

export default EditGender;
